import { ApiClient } from 'api'
import { getName, ValOrCalc } from 'redux/utils'
import { AwaitedReturnType } from '.'

type OptionalMessages = {
  [Key in keyof ApiClient]?: ValOrCalc<
    string,
    Parameters<ApiClient[Key]>[0],
    AwaitedReturnType<ApiClient[Key]>
  > | null
}

export const successMessages: OptionalMessages = {
  updateStoreSetup: 'Store content synced successfully.',
  deleteStoreSetup: 'Store deleted.',
  createIntegration: 'Created integration.',
  updateIntegration: 'Updated integration.',
  deleteIntegration: 'Deleted integration.',
  updateUser: data => `Saved changes to ${getName(data)}.`,
  deleteUser: 'Successfully deleted user.',
  inviteUser: 'Successfully invited new user.',
  createTenant: 'Tenant created successfully',
  createDashboard: 'Dashboard created successfully',
  replaceDashboard: 'Dashboard replaced successfully',
  publishDashboard: 'Dashboard published successfully',
  copyAudience: 'Audience created successfully',
}

export const failureMessages: OptionalMessages = {
  updateStoreSetup: 'An error occurred while trying to update the store.',
  deleteStoreSetup: 'An error occurred while trying to delete the store.',
  createIntegration: 'An error occurred while trying to create the integration',
  updateIntegration:
    'An error occurred while trying to update the integration.',
  deleteIntegration:
    'An error occurred while trying to delete the integration.',
  runLook: 'An error occurred while fetching some metric tile data.',
  getTiles: (_, __, err) =>
    err?.response?.data?.details?.includes('login failed')
      ? null // return null to do not show error notification
      : err?.response?.data?.details,
  updateUser: data =>
    `An error occurred attempting to update user ${getName(data)}`,
  deleteUser: 'An error occurred while trying to delete user.',
  inviteUser: 'An error occurred while trying to invite new user.',
  provisionTenant: (req, res, error) =>
    error?.response?.data || 'An error while attempting to sign up',
  getContentStoreInsights: null,
  getDataStoreInsights: null,
  getOnlineStoreInsights: null,
  getOrderStoreInsights: null,
  getBuildStatus: null,
  getDeliveryMetrics: null,
  getEventVolume: null,
  getStripeWebhooks: null,
  getTrialExpiration: null,
  getOnboardingStatus: null,
  createTenant: 'An error occurred while trying to create the tenant',
  createDashboard: 'An error occurred while trying to create the dashboard',
  replaceDashboard: 'An error occurred while trying to replace the dashboard',
  publishDashboard: 'An error occurred while trying to publish the dashboard',
  copyAudience: 'An error occurred while trying to create the audience',
}
