import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from 'react'
import styled from 'styled-components'
import { useIsMobile } from 'hooks'
import {
  Button,
  Modal,
  Spinner,
  standardIcons,
  useTheme,
} from '@chordco/component-library'
import { useCensus } from '../CensusContext'
import { useNotifications } from 'redux/state/notifications'
import { v4 as generateUUID } from 'uuid'
import { actions, reducer, initialState } from '../store'
import { useAuthData } from 'redux/state/auth'
import { CensusEmbedSyncResult, CensusSync } from 'api/census/interfaces'
import { SyncTable } from './SyncTable'
import SyncCreateWizard from './SyncCreateWizard'
import { StaticFormSheet } from 'components/common/StaticFormSheet'
import SyncEditWizard from './SyncEditWizard'

const { CloseX } = standardIcons

interface SyncsProps {
  onEditSchedule: (sync: CensusSync) => void
  onClose: () => void
}

export interface SyncsRef {
  refreshAndFetch: () => void
}

export const Syncs = forwardRef<SyncsRef, SyncsProps>((props, ref) => {
  const { onEditSchedule, onClose } = props

  const {
    state: { user },
  } = useAuthData()

  const theme = useTheme()

  const { censusClient } = useCensus()

  const { addNotification } = useNotifications()

  const [state, dispatch] = useReducer(reducer, initialState)

  const isMobile = useIsMobile()

  const [showAddSync, setShowAddSync] = useState(false)
  const [editSync, setEditSync] = useState<number | null>(null)

  const fetchSyncs = useCallback(
    async (page: number) => {
      dispatch(actions.fetchStart())

      try {
        const response = await censusClient?.getSyncs(page)

        if (response) {
          const { data, pagination } = response
          const syncs = data ?? []

          dispatch(
            actions.fetchSuccess(
              syncs,
              pagination.page,
              pagination.nextPage,
              pagination.prevPage
            )
          )
        } else {
          dispatch(actions.fetchError(new Error('No data returned')))
        }
      } catch (error: any) {
        dispatch(actions.fetchError(error))
      }
    },
    [censusClient]
  )

  const refreshAndFetch = useCallback(() => {
    const fetchData = async () => {
      try {
        dispatch(actions.refresh())
        await fetchSyncs(1)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error in refreshAndFetch:', error)
      }
    }

    fetchData()
  }, [dispatch, actions.refresh, fetchSyncs])

  /**
   * Fetch initial page of syncs on mount
   */
  useEffect(() => {
    fetchSyncs(1)
  }, [])

  // We use useImperativeHandle to expose a refreshAndFetch method to the parent component
  useImperativeHandle(ref, () => ({
    refreshAndFetch,
  }))

  /**
   * Fetch syncs for the next page
   */
  const handleNextPage = useCallback(() => {
    if (state.nextPage) {
      fetchSyncs(state.nextPage)
    }
  }, [state.nextPage, fetchSyncs])

  /**
   * Fetch sync for the previous page
   */
  const handlePrevPage = useCallback(() => {
    if (state.previousPage) {
      fetchSyncs(state.previousPage)
    }
  }, [state.previousPage, fetchSyncs])

  const handleCreatedSync = async (result: CensusEmbedSyncResult) => {
    setShowAddSync(false)
    refreshAndFetch()

    if (result.status === 'created') {
      addNotification({
        id: generateUUID(),
        type: 'success',
        message: 'Your sync has been successfully created',
      })
    }
  }

  const handleRunSync = async (sync: CensusSync) => {
    await censusClient?.triggerSyncRun(sync.id)
    refreshAndFetch()
  }

  const handleCancelSync = async (sync: CensusSync) => {
    setShowAddSync(false)

    const latestSyncRun = await censusClient?.getSyncRuns(sync.id)

    if (!latestSyncRun?.data[0].id) {
      addNotification({
        id: generateUUID(),
        type: 'warning',
        message: 'Could not find the latest sync run for this sync.',
      })
      return
    }

    const result = await censusClient?.cancelSyncRun(latestSyncRun.data[0].id)

    if (result?.status === 'cancelled') {
      addNotification({
        id: generateUUID(),
        type: 'success',
        message: 'Sync has been successfully cancelled',
      })
    } else {
      addNotification({
        id: generateUUID(),
        type: 'warning',
        message:
          'An error occurred while cancelling your sync. Please try again.',
      })
    }

    refreshAndFetch()
  }

  const handleDeleteSync = async (sync: CensusSync) => {
    setShowAddSync(false)

    const result = await censusClient?.deleteSync(sync.id)

    if (result?.status === 'deleted') {
      addNotification({
        id: generateUUID(),
        type: 'success',
        message: 'Sync has been successfully deleted',
      })
    } else {
      addNotification({
        id: generateUUID(),
        type: 'warning',
        message:
          'An error occurred while deleting your sync. Please try again.',
      })
    }

    refreshAndFetch()
  }

  const handleEditSync = async (sync: CensusSync) => {
    setEditSync(sync.id)
  }

  const handleEditedSync = (result: CensusEmbedSyncResult) => {
    setEditSync(null)
    refreshAndFetch()

    if (result.status === 'created') {
      addNotification({
        id: generateUUID(),
        type: 'success',
        message: 'Your sync has been successfully updated',
      })
    }
  }

  const showAddSyncButton = user?.role === 'admin' || user?.role === 'superuser'

  return (
    <StaticFormSheet
      title="My Syncs"
      onClose={onClose}
      isLarge={false}
      closeOnClickOutside={false}
      customWidth="70%"
    >
      <Container isMobile={isMobile}>
        {showAddSync && (
          <Modal
            onClose={() => setShowAddSync(false)}
            title="Create Sync"
            width="50%"
            padding="0"
          >
            <SyncCreateWizard onSyncCreated={handleCreatedSync} />
          </Modal>
        )}

        {editSync && (
          <Modal
            onClose={() => setEditSync(null)}
            title="Edit Sync"
            width="50%"
            padding="0"
          >
            <SyncEditWizard syncId={editSync} onSyncEdited={handleEditedSync} />
          </Modal>
        )}

        <Header>
          <Button
            type="button"
            purpose="ghost"
            onClick={refreshAndFetch}
            icon={standardIcons.Refresh}
          >
            Refresh
          </Button>

          {showAddSyncButton && (
            <Button
              type="button"
              onClick={() => setShowAddSync(true)}
              icon={standardIcons.Plus}
            >
              Add New Sync
            </Button>
          )}
        </Header>

        {state.loading && (
          <LoaderContainer>
            <Spinner scale={30} />
          </LoaderContainer>
        )}

        {!state.loading && (
          <>
            {state.items && state.items.length > 0 ? (
              <SyncTable
                syncs={state.items as CensusSync[]}
                onRunSync={handleRunSync}
                onDeleteSync={handleDeleteSync}
                onCancelSync={handleCancelSync}
                onEditSync={handleEditSync}
                onUpdateSyncSchedule={onEditSchedule}
                onPrevPage={handlePrevPage}
                onNextPage={handleNextPage}
                showNextPageButton={state.nextPage !== null}
                showPreviousPageButton={state.previousPage !== null}
              />
            ) : (
              <NoSyncs>No sync yet</NoSyncs>
            )}
          </>
        )}

        {state.error && (
          <ErrorContainer>
            <div>An error occurred while fetching your syncs.</div>
            <span onClick={() => dispatch(actions.fetchError(undefined))}>
              <CloseX fill={theme.GREY_80} />
            </span>
          </ErrorContainer>
        )}
      </Container>
    </StaticFormSheet>
  )
})

const Container = styled.div<{ isMobile: boolean }>`
  padding: ${p => (p.isMobile ? 12 : 24)}px;
  padding: 0;
  position: relative;

  & > div {
    margin-bottom: 16px;

    :last-child {
      margin-bottom: 0;
    }
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
`

const LoaderContainer = styled.div`
  position: relative;
  height: 200px;
  width: 100%;
`

const ErrorContainer = styled.div`
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${p => p.theme.RED_30};
  border-radius: 8px;
  color: ${p => p.theme.GREY_80};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 12px;

  & > span {
    cursor: pointer;
  }
`

const NoSyncs = styled.div`
  width: 100%;
  border-radius: 8px;
  background: ${p => p.theme.GREY_30};
  padding: 12px;
  font-size: 12px;
  color: ${p => p.theme.GREY_80};
`
