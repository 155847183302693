import React from 'react'
import { MRT_TableOptions, MRT_RowData } from 'material-react-table'
import styled from 'styled-components'

import { Sheet, Button } from '@chordco/component-library'
import { Trash } from '@chordco/component-library/components/icons/standardIcons/Trash'

/**
 * This function returns a partial MRT_TableOptions object that will render a dialog for creating a new row.
 * Most of the logic has been extracted from the built-in dialog box so that we can customize it.
 * https://github.com/KevinVandy/material-react-table/blob/v2/packages/material-react-table/src/components/buttons/MRT_EditActionButtons.tsx
 */
export function getTableEditRowDialog<TData extends MRT_RowData>(
  openDeleteConfirmModal: ((row: any) => void) | undefined
): Partial<MRT_TableOptions<TData>> {
  return {
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => {
      const {
        getState,
        options: { onEditingRowCancel, onEditingRowSave },
        refs: { editInputRefs },
        setEditingRow,
      } = table

      const { editingRow, isSaving } = getState()

      const isEditing = editingRow?.id === row.id

      const handleCancel = () => {
        onEditingRowCancel?.({ row, table })
        setEditingRow(null)

        row._valuesCache = {} as any //reset values cache
      }

      const handleSubmitRow = async () => {
        //look for auto-filled input values
        Object.values(editInputRefs?.current)
          .filter(inputRef => row.id === inputRef?.name?.split('_')?.[0])
          ?.forEach(input => {
            if (
              input.value !== undefined &&
              Object.hasOwn(row?._valuesCache as object, input.name)
            ) {
              // @ts-ignore
              row._valuesCache[input.name] = input.value
            }
          })

        onEditingRowSave?.({
          exitEditingMode: () => setEditingRow(null),
          row,
          table,
          values: row._valuesCache,
        })
      }

      const handleDeleteRow = async () => {
        onEditingRowCancel?.({ row, table })
        setEditingRow(null)

        row._valuesCache = {} as any //reset values cache

        openDeleteConfirmModal?.(row)
      }

      return isEditing ? (
        <Sheet
          title={`Edit Row ${row.index + 1}`}
          onClose={handleCancel}
          closeOnClickOutside={false}
        >
          <Container>
            {internalEditComponents}
            <Bottom>
              <Button
                onClick={handleSubmitRow}
                purpose="primary"
                type="button"
                isLoading={isSaving}
              >
                Save
              </Button>
              <Button
                onClick={handleDeleteRow}
                purpose="delete-secondary"
                type="button"
                isLoading={isSaving}
                icon={Trash}
              >
                Delete
              </Button>
            </Bottom>
          </Container>
        </Sheet>
      ) : null
    },
  }
}

const Container = styled.div`
  min-height: calc(100vh - 76px);
  overflow: auto;
  padding: 24px 24px 100px 24px;
  position: relative;
`

const Bottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${p => p.theme.ComponentWorkspaceBgPage};
  border-top: 1px solid ${p => p.theme.BorderHairline};
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
`
