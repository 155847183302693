import { useEffect, useLayoutEffect, useState } from 'react'

const useClickListener = (handleClick: any) => {
  useEffect(() => {
    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}

export const useOutsideClick = (
  active: boolean,
  ref: any,
  callback: Function
) =>
  useClickListener((e: any) => {
    if (!active || !document.contains(e.target)) return

    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  })

export const useOutsideClickMultiple = (
  active: boolean,
  refs: any[],
  callback: Function
) =>
  useClickListener((e: any) => {
    if (!active || !document.contains(e.target)) return

    let outside = true
    refs.forEach((ref: any) => {
      if (ref.current && ref.current.contains(e.target)) {
        outside = false
      }
    })

    if (outside) {
      callback()
    }
  })

export const useElementWidth = (ref: any) => {
  const [inputWidth, setInputWidth] = useState(0)

  useLayoutEffect(() => {
    setInputWidth(ref.current?.getBoundingClientRect().width || 0)
  }, [ref.current])

  return inputWidth
}
