import { useIsLoading } from 'redux/utils'
import { useLookerData } from 'redux/state/looker'

export const useReplaceDashboard = (onClose: () => void) => {
  const { replaceDashboard } = useLookerData()

  const isLoading = useIsLoading('replaceDashboard')

  const submit = async ({
    oldDashboardSlug,
    newDashboardSlug,
  }: {
    oldDashboardSlug: string
    newDashboardSlug: string
  }) => {
    if (!replaceDashboard) return

    await replaceDashboard(oldDashboardSlug, newDashboardSlug)

    onClose()
  }

  return { submit, isLoading }
}
