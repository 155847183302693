import { hubClient } from '../http'
import { RolePermission } from 'types'

export interface RoleClient {
  getRolePermissionDescriptions: () => Promise<RolePermission[]>
}

export const roleClient: RoleClient = {
  getRolePermissionDescriptions: () =>
    hubClient.get('role_permission_descriptions'),
}
