import React, { useState } from 'react'
import { AvatarSize } from '@chordco/component-library'
import { useIsMobile } from 'hooks'
import { FormSheet } from '../../../common'
import { LoginLink } from './common'
import { ConfirmationModal } from '../ConfirmationModal'

type Props = {
  children?: React.ReactNode
  title: string
  name: string
  logo: string
  loginLink: string
  isLoading: boolean
  isEdit: boolean
  submit: (e?: any) => void
  onClose: () => void
  customWidth?: string
  isLarge?: boolean
}

export const IntegrationForm: React.FC<Props> = ({
  title,
  name,
  logo,
  loginLink,
  isLoading,
  isEdit,
  submit,
  onClose,
  children,
  customWidth,
  isLarge = true,
}) => {
  const isMobile = useIsMobile()
  const linkSize: AvatarSize = isMobile ? 32 : 48

  const [showConfirmation, setShowConfirmation] = useState<boolean>(false)

  const onSubmit = e => {
    e?.preventDefault()
    setShowConfirmation(true)
  }

  const onConfirm = e => {
    e?.preventDefault()
    setShowConfirmation(false)
    submit(e)
  }

  const onCancel = () => setShowConfirmation(false)

  return (
    <FormSheet
      onSubmit={onSubmit}
      onClose={onClose}
      title={title}
      isEdit={isEdit}
      isLoading={isLoading}
      isLarge={isLarge}
      closeOnClickOutside
      customWidth={customWidth}
    >
      <LoginLink
        name={name}
        logo={logo}
        logoSize={linkSize}
        loginLink={loginLink}
      />
      {children}
      {showConfirmation && (
        <ConfirmationModal onConfirm={onConfirm} onCancel={onCancel} />
      )}
    </FormSheet>
  )
}
