import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import {
  GetAvailableReportsResponse,
  GetAvailableUserReportsResponse,
} from 'types'

const useMetricSlug = (
  isExplores: boolean,
  isUserDashboards: boolean,
  availableReports: GetAvailableReportsResponse,
  availableUserReports: GetAvailableUserReportsResponse
): string => {
  const history = useHistory()

  const slug = useMemo(() => {
    const pathSegments = history.location.pathname.split('/')
    const pathSlug = pathSegments[pathSegments.length - 1]

    const reports = isExplores
      ? availableReports?.explores
      : isUserDashboards
      ? availableUserReports?.userDashboards
      : []

    const foundSlug = reports?.find(({ slug }) => slug === pathSlug)?.slug

    // We have a slug in the path, return it
    if (foundSlug) {
      return foundSlug
    }

    // If the current Explore path has no slug, default to the first available Explore
    if (isExplores && availableReports?.explores?.length > 0) {
      return availableReports.explores[0].slug
    }

    // If the current User Dashboard path has no slug, default to the first available User Dashboard
    if (isUserDashboards && availableUserReports?.userDashboards?.length > 0) {
      return availableUserReports.userDashboards[0].slug
    }

    // If we have no available reports, return an empty string
    return ''
  }, [
    history.location.pathname,
    isExplores,
    isUserDashboards,
    availableReports,
    availableUserReports,
  ])

  return slug
}

export default useMetricSlug
