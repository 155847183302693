import { useEverySucceeded, useIsLoading } from 'redux/utils'
import { useLookerData } from 'redux/state/looker'
import { useEffect } from 'react'

interface ActivateAudienceData {
  audienceName: string
  audienceDescription: string
  audienceId: string
}

export const useActivateAudience = (onActivate: () => void) => {
  const { activateAudience } = useLookerData()

  const isLoading = useIsLoading('activateAudience')
  const hasSucceeded = useEverySucceeded('activateAudience')

  const submit = async ({
    audienceName,
    audienceDescription,
    audienceId,
  }: ActivateAudienceData) => {
    if (!activateAudience) return

    await activateAudience(audienceName, audienceDescription, audienceId)
  }

  useEffect(() => {
    if (hasSucceeded) {
      onActivate()
    }
  }, [hasSucceeded, onActivate])

  return { submit, isLoading }
}
