import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { TableRow } from 'components/common/table'
import { Button, Caption, Chip, H3 } from '@chordco/component-library'
import { AvailableReport } from 'types'
import { getDescriptionForReport, getIconForReport } from './metadata'
import { EnvVarConfig } from 'utils/envVarConfig'

type ReportCardProps = {
  report: AvailableReport
  onSelectReport: (report: AvailableReport) => void
}

export const ReportCard: React.FC<ReportCardProps> = ({
  report,
  onSelectReport,
}) => {
  const newThreshold =
    parseInt(EnvVarConfig.REACT_APP_NEW_DASHBOARD_DAYS_THRESHOLD) || 3

  const isNew = moment().diff(moment(report.createdAt), 'days') <= newThreshold

  return (
    <Card onClick={() => onSelectReport(report)}>
      <CardContent>
        <Header>
          <StyledIcon
            icon={getIconForReport(report)}
            purpose="ghost"
            size="large"
          />
          <TextContainer>
            <TitleBadgesContainer>
              <Title>{report.label}</Title>
              <ChipContainer>
                {report.createdBy && (
                  <Chip
                    text={`By ${
                      report.createdBy.firstName
                    } ${report.createdBy.lastName.slice(0, 1)}.`}
                    color="neutral"
                    variant="solid-secondary"
                  />
                )}
                {isNew && <Chip text="New" color="limeGreen" variant="solid" />}
              </ChipContainer>
            </TitleBadgesContainer>

            <Description>
              {report.description ?? getDescriptionForReport(report)}
            </Description>
          </TextContainer>
        </Header>
      </CardContent>
    </Card>
  )
}

const Card = styled(TableRow)`
  height: 108px;
  border: 1px solid ${p => p.theme.ComponentCardBorderElevation0};
  border-radius: ${p => p.theme.CardMediumRadius};

  display: flex;
  align-content: center;

  img {
    margin-right: 16px;
  }

  :hover {
    border: 1px solid ${p => p.theme.ComponentCardBorderElevation0};
    background-color: ${p => p.theme.ComponentCardBgElevation1};
  }
`

const CardContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 16px;
`

const Title = styled(H3)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  display: inline-block;
`

const Description = styled(Caption)`
  color: ${p => p.theme.ContentTertiary};
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limits to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  width: 100%;
`

const TitleBadgesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`

const StyledIcon = styled(Button)`
  width: 60px;
  height: 60px;
  border-radius: 8px;
  background-color: ${p => p.theme.ComponentCardBgElevation1};

  :hover,
  :active,
  :focus {
    background-color: ${p => p.theme.ComponentCardBgElevation1};
    border-color: transparent;
    outline: none;
  }
`

const ChipContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 8px;
  margin-right: 8px;
`

