import React from 'react'
import { Dropdown, DropdownProps, OptionProps } from '../index'
import styled from 'styled-components'
import moment from 'moment'
import { standardIcons } from '../icons'
import { DateRange } from 'types'

export type DateDropdownPeriod = 'today' | 'week' | 'month' | 'year'

type Props = Omit<DropdownProps, 'control' | 'options'> & {
  allowedOptions?: string[]
}

const dateValueMap: { [key: number]: moment.Moment } = {
  1: moment(),
  2: moment().subtract(1, 'week'),
  3: moment().subtract(30, 'day'),
  4: moment().subtract(90, 'day'),
  5: moment().subtract(1, 'year'),
  6: moment().clone().startOf('week'),
  7: moment().clone().startOf('month'),
  8: moment().clone().startOf('year'),
}

const end = moment().format('YYYY-MM-DD')

export const getDateRangeById: (id: number) => DateRange = id => {
  const start = dateValueMap[id].format('YYYY-MM-DD')

  return { start, end }
}

const options: OptionProps[] = [
  { id: 'year', label: 'Year to date' },
  { id: 'month', label: 'Month to date' },
  { id: 'week', label: 'Week to date' },
  { id: 'today', label: 'Today' },
]

const { ChevronDown } = standardIcons

export const DateDropdown: React.FC<Props> = ({
  allowedOptions = ['today', 'week', 'month', 'year'],
  ...props
}) => {
  return (
    <Dropdown
      {...props}
      options={options.filter(o => allowedOptions.includes(String(o.id)))}
      control={
        <Control>
          {options.find(o => o.id === props.selected)?.label}
          <ChevronContainer>
            <ChevronDown />
          </ChevronContainer>
        </Control>
      }
    />
  )
}

const Control = styled.div`
  position: relative;
  width: 280px;
  padding: 10px 38px 10px 12px;
  background-color: ${p => p.theme.ComponentWorkspaceBgPage};
  border: solid 1px ${p => p.theme.BorderStrong};
  border-radius: ${p => p.theme.FieldRadius};
  cursor: pointer;
`

const ChevronContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 12px;
  fill: ${p => p.theme.ContentAccentPrimary};
`
