import { hubClient } from '../http'
import {
  AvailableUserAudience,
  AvailableUserReport,
  GetAvailableAudiencesResponse,
  GetAvailableReportsResponse,
  GetAvailableUserAudiencesResponse,
  GetAvailableUserReportsResponse,
  GetEmbedUrlResponse,
  GetTilesRequest,
  GetTilesResponse,
  LookerEmbedUser,
  LookerReportType,
  MetricsLookupIds,
} from 'types'

export interface LookerClient {
  getAvailableReports: (data: {
    tenantId: number
    storeId: number
  }) => Promise<GetAvailableReportsResponse>

  getAvailableUserReports: (data: {
    tenantId: number
    storeId: number
  }) => Promise<GetAvailableUserReportsResponse>

  getAvailableAudiences: (data: {
    tenantId: number
  }) => Promise<GetAvailableAudiencesResponse>

  getAvailableUserAudiences: (data: {
    tenantId: number
  }) => Promise<GetAvailableUserAudiencesResponse>

  getEmbedUrl: (data: {
    tenantId: number
    storeId: number
    slug: string
    type: LookerReportType
    theme?: string
  }) => Promise<GetEmbedUrlResponse>

  getEmbedUserUrl: (data: {
    tenantId: number
    storeId: number
    slug: string
    type: LookerReportType
    theme?: string
  }) => Promise<GetEmbedUrlResponse>

  getEmbedAudienceUrl: (data: {
    tenantId: number
    slug: string
    type: LookerReportType
    theme?: string
  }) => Promise<GetEmbedUrlResponse>

  getEmbedUserAudienceUrl: (data: {
    tenantId: number
    slug: string
    theme?: string
  }) => Promise<GetEmbedUrlResponse>

  runLook: (data: {
    tenantId: number
    storeId: number
    lookId: number
  }) => Promise<any>

  getTiles: (data: GetTilesRequest) => Promise<GetTilesResponse[]>

  getMetrics: (data: {
    tenantId: number
    storeId: number
    metricIds: (MetricsLookupIds | number)[]
  }) => Promise<GetTilesResponse[]>

  createDashboard: (data: {
    tenantId: number
    storeId: number
    title: string
    folderId: string
  }) => Promise<AvailableUserReport>

  getEmbedUser: () => Promise<LookerEmbedUser>

  createEmbedUser: () => Promise<LookerEmbedUser>

  copyDashboard: (data: {
    tenantId: number
    storeId: number
    dashboardSlug: string
    folderId: string
  }) => Promise<AvailableUserReport>

  replaceDashboard: (data: {
    tenantId: number
    storeId: number
    oldDashboardSlug: string
    newDashboardSlug: string
  }) => Promise<AvailableUserReport>

  publishDashboard: (data: {
    tenantId: number
    storeId: number
    dashboardName: string
    dashboardDescription: string
    dashboardSlug: string
  }) => Promise<AvailableUserReport>

  copyAudience: (data: {
    tenantId: number
    audienceId: string
    audienceTitle: string
    audienceDescription: string
    folderId: string
  }) => Promise<AvailableUserAudience>

  activateAudience: (data: {
    tenantId: number
    audienceId: string
    audienceName: string
    audienceDescription: string
  }) => Promise<AvailableUserAudience>
}

export const lookerClient: LookerClient = {
  getAvailableReports: ({ tenantId, storeId }) =>
    hubClient.get(`tenants/${tenantId}/stores/${storeId}/metrics`),

  getAvailableUserReports: ({ tenantId, storeId }) =>
    hubClient.get(`tenants/${tenantId}/stores/${storeId}/metrics/user_metrics`),

  getAvailableAudiences: ({ tenantId }) =>
    hubClient.get(`tenants/${tenantId}/audiences`),

  getAvailableUserAudiences: ({ tenantId }) =>
    hubClient.get(`tenants/${tenantId}/audiences/user_audiences`),

  getEmbedUrl: ({ tenantId, storeId, slug, type, theme }) =>
    hubClient.get(`tenants/${tenantId}/stores/${storeId}/metrics/embed`, {
      params: { slug, metricType: type, theme },
    }),

  getEmbedUserUrl: ({ tenantId, storeId, slug, type, theme }) =>
    hubClient.get(
      `tenants/${tenantId}/stores/${storeId}/metrics/embed_user_metric`,
      {
        params: { slug, metricType: type, theme },
      }
    ),

  getEmbedAudienceUrl: ({ tenantId, slug, theme }) =>
    hubClient.get(`tenants/${tenantId}/audiences/embed`, {
      params: { slug, theme, audience_type: 'prebuilt' },
    }),

  getEmbedUserAudienceUrl: ({ tenantId, slug, theme }) =>
    hubClient.get(`tenants/${tenantId}/audiences/embed`, {
      params: { slug, theme, audience_type: 'user' },
    }),

  runLook: ({ tenantId, storeId, lookId }) =>
    hubClient.get(
      `tenants/${tenantId}/stores/${storeId}/metrics/run/${lookId}`
    ),

  getTiles: ({ tenantId, storeId, timePeriod }) =>
    hubClient.get(
      `/tenants/${tenantId}/stores/${storeId}/metrics/overview?time_period=${timePeriod}`
    ),

  getMetrics: ({ tenantId, storeId, metricIds }) =>
    hubClient.get(`tenants/${tenantId}/stores/${storeId}/metrics/metric`, {
      params: { metric_id: metricIds },
    }),

  createDashboard: ({ tenantId, storeId, title, folderId }) =>
    hubClient.post(
      `tenants/${tenantId}/stores/${storeId}/metrics/create_dashboard`,
      {
        title,
        folder_id: folderId,
      }
    ),

  getEmbedUser: () => hubClient.get(`looker_users/find_user`),
  createEmbedUser: () => hubClient.post(`looker_users/create_user`),

  copyDashboard: ({ tenantId, storeId, dashboardSlug, folderId }) =>
    hubClient.post(
      `tenants/${tenantId}/stores/${storeId}/metrics/copy_dashboard`,
      {
        dashboard_slug: dashboardSlug,
        folder_id: folderId,
      }
    ),

  replaceDashboard: ({
    tenantId,
    storeId,
    oldDashboardSlug,
    newDashboardSlug,
  }) =>
    hubClient.put(
      `tenants/${tenantId}/stores/${storeId}/metrics/replace_dashboard/${oldDashboardSlug}`,
      {
        new_dashboard_slug: newDashboardSlug,
        metric_type: 'dashboard',
      }
    ),

  publishDashboard: ({
    tenantId,
    storeId,
    dashboardName,
    dashboardDescription,
    dashboardSlug,
  }) =>
    hubClient.post(
      `tenants/${tenantId}/stores/${storeId}/metrics/publish_dashboard`,
      {
        dashboard_name: dashboardName,
        dashboard_description: dashboardDescription,
        dashboard_slug: dashboardSlug,
      }
    ),

  copyAudience: ({
    tenantId,
    audienceId,
    audienceTitle,
    audienceDescription,
    folderId,
  }) =>
    hubClient.post(`tenants/${tenantId}/audiences/copy_audience`, {
      audience_id: audienceId,
      audience_title: audienceTitle,
      audience_description: audienceDescription,
      folder_id: folderId,
    }),

  activateAudience: ({
    tenantId,
    audienceId,
    audienceName,
    audienceDescription,
  }) =>
    hubClient.post(`tenants/${tenantId}/audiences/activate_audience`, {
      audience_id: audienceId,
      audience_name: audienceName,
      audience_description: audienceDescription,
    }),
}
