import React, {
  cloneElement,
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useOutsideClickMultiple } from '../hooks'
import { Option, OptionProps } from './Option'
import { Search } from './Search'
import { Button } from '../controls'
import { Clear, RelativeContainer, Select, Title } from './styles'
import { InverseThemeProvider } from 'themes/ThemeSwitcherProvider'

export interface DropdownProps {
  options: OptionProps[]
  selected?: string | number
  hideScrollbar?: boolean
  onSelect?: Function
  control: any
  search?: boolean
  selectable?: boolean
  checkable?: boolean
  left?: boolean
  startOpen?: boolean
  title?: string
  onClear?: () => void
  onClearText?: string
  customWidth?: string
}

export const Dropdown: React.FC<DropdownProps> = ({
  options,
  selected,
  onSelect,
  control,
  search,
  selectable,
  checkable,
  left = false,
  startOpen = false,
  title,
  onClear,
  onClearText,
  ...props
}) => {
  const [open, setOpen] = useState<boolean>(startOpen)
  const [show, setShow] = useState<boolean>(startOpen)
  const [searchValue, setSearchValue] = useState<string>('')
  const [activeOptionId, setActiveOptionId] = useState<number | string>()

  const dropdownRef = useRef() as any
  const controlRef = useRef() as any

  const closeDropdown = (e?: MouseEvent) => {
    e?.stopPropagation()
    setShow(false)
    setTimeout(() => setOpen(false))
  }

  useOutsideClickMultiple(open, [dropdownRef, controlRef], closeDropdown)

  const openDropdown = (e?: MouseEvent) => {
    e?.stopPropagation()
    setOpen(true)
    setTimeout(() => setShow(true))
  }

  const filteredOptions = search
    ? options.filter(option =>
        option.label.toUpperCase().includes(searchValue.toUpperCase())
      )
    : options

  const onSelectOption = (id: number | string) => {
    onSelect && onSelect(id)

    if (!checkable) {
      closeDropdown()
    }

    const option = options.find(o => o.id === id)
    option?.onClick && option.onClick()
  }

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = e => {
    if (
      !open ||
      !['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Enter'].includes(
        e.key
      )
    )
      return

    e.preventDefault()
    const index = filteredOptions.findIndex(o => activeOptionId === o.id)

    if (e.key === 'ArrowDown' || e.key === 'ArrowLeft') {
      setActiveOptionId(filteredOptions[index + 1]?.id)
    } else if (e.key === 'ArrowUp' || e.key === 'ArrowRight') {
      setActiveOptionId(filteredOptions[index - 1]?.id)
    } else if (e.key === 'Enter' || e.key === ' ') {
      setActiveOptionId(filteredOptions[index]?.id)
      onSelectOption(filteredOptions[index]?.id)
    }
  }

  const searchRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (open) searchRef?.current?.focus()
  }, [open])

  return (
    <RelativeContainer onKeyDown={onKeyDown} tabIndex={-1}>
      <div ref={controlRef}>
        {cloneElement(control, {
          onClick: open ? closeDropdown : openDropdown,
          selected: open,
        })}
      </div>
      {open && (
        <InverseThemeProvider>
          <Select
            {...props}
            ref={dropdownRef}
            show={show}
            left={left}
            searchable={search}
          >
            {search && (
              <Search
                inputRef={searchRef}
                value={searchValue}
                onChange={(e: any) => setSearchValue(e.target.value)}
                onKeyDown={onKeyDown}
              />
            )}
            {title && <Title>{title}</Title>}
            {filteredOptions.map(({ id, ...option }) => (
              <Option
                {...option}
                data-selector={`option-${id}`}
                key={id}
                onClick={e => {
                  e.stopPropagation()
                  onSelectOption(id)
                }}
                // @ts-ignore
                onMouseEnter={() => setActiveOptionId(id)}
                onMouseLeave={() => setActiveOptionId(undefined)}
                selected={selected === id}
                arrowHovered={activeOptionId === id}
                selectable={selectable}
                leftIcon={option.leftIcon}
                rightIcon={option.rightIcon}
              />
            ))}

            {onClear && !!selected && (
              <Clear>
                <Button onClick={onClear} purpose="secondary" size="small">
                  {onClearText || 'Clear'}
                </Button>
              </Clear>
            )}
          </Select>
        </InverseThemeProvider>
      )}
    </RelativeContainer>
  )
}
