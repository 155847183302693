import React from 'react'
import {
  Route,
  Switch,
  RouteComponentProps,
  useRouteMatch,
} from 'react-router-dom'
import {
  Analytics,
  AnalyticsFeatureFlags,
} from 'components/analytics/Analytics'
import { Dashboard } from 'components/looker/Dashboard'

interface AnalyticsRoutesProps extends RouteComponentProps {
  featureFlags: AnalyticsFeatureFlags
}

const AnalyticsRoutes: React.FC<AnalyticsRoutesProps> = ({
  match,
  featureFlags,
}) => {
  // Reports are rendered fullpage, so we don't want to show the Analytics component
  const isReportRoute = useRouteMatch(`${match.path}/reports/:slug`)

  return (
    <div>
      {!isReportRoute && <Analytics featureFlags={featureFlags} />}
      <Switch>
        <Route path={`${match.path}/reports/:slug`} component={Dashboard} />
      </Switch>
    </div>
  )
}

export default AnalyticsRoutes
