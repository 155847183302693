import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Button, Label, Modal, Spinner } from '@chordco/component-library'
import { FormInput, FormRadioButton } from 'components/common'
import { useLookerData } from 'redux/state/looker'
import { useReplaceDashboard } from './useReplaceDashboard'
import { useForm } from 'hooks'
import { useIsLoading } from 'redux/utils'

interface SetDefaultDashboardModalProps {
  dashboardSlug: string
  onClose: () => void
}

export const SetDefaultDashboardModal: React.FC<
  SetDefaultDashboardModalProps
> = ({ dashboardSlug, onClose }) => {
  const {
    getAvailableReports,
    state: { availableReports },
  } = useLookerData()

  const { submit, isLoading: isSubmitting } = useReplaceDashboard(onClose)

  const { fields, onSubmit } = useForm(
    {
      oldDashboardSlug: {
        label: 'Old Dashboard Slug',
        type: 'string',
        initialValue: '',
        required: true,
      },
      newDashboardSlug: {
        label: 'Dashboard ID',
        type: 'string',
        initialValue: dashboardSlug,
        required: true,
      },
    },
    submit
  )

  const isLoadingAvailableReports = useIsLoading('getAvailableReports')

  useEffect(() => {
    getAvailableReports && getAvailableReports()
  }, [])

  return (
    <Modal
      onClose={onClose}
      title="Set Default Dashboard"
      width="480px"
      closeOnClickOutside={false}
    >
      <form onSubmit={onSubmit} noValidate>
        <Section>
          <StyledLabel>
            You can use this form to replace one of our default dashboards with
            your own. Once replaced, the new dashboard will become the default
            for all your team members.
          </StyledLabel>
          <FormInput
            field={fields.newDashboardSlug}
            customWidth="100%"
            disabled
            required
          />

          {isLoadingAvailableReports && <Spinner scale={30} />}

          {!isLoadingAvailableReports &&
            availableReports.dashboards.length > 0 && (
              <RadioButtonsContainer>
                <Label>Please select a dashboard to replace:</Label>
                <RadioButtonsGroup>
                  {availableReports.dashboards.map(dashboard => (
                    <StyledRadioButton
                      key={dashboard.slug}
                      field={{
                        name: 'Dashboard Slug',
                        label: dashboard.label,
                        type: 'boolean',
                        setValue: () =>
                          fields.oldDashboardSlug.setValue(dashboard.slug),
                        value: dashboard.slug === fields.oldDashboardSlug.value,
                      }}
                    />
                  ))}
                </RadioButtonsGroup>
              </RadioButtonsContainer>
            )}
        </Section>

        <BottomSection>
          <Button purpose="tertiary" onClick={onClose}>
            Cancel
          </Button>

          <Button type="submit" purpose="primary" isLoading={isSubmitting}>
            Set as Default
          </Button>
        </BottomSection>
      </form>
    </Modal>
  )
}

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledLabel = styled(Label)`
  white-space: pre-wrap;
`

const BottomSection = styled.div`
  width: 100%;
  padding: 16px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`

const RadioButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 8px;
`

const RadioButtonsGroup = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 10px;
`

const StyledRadioButton = styled(FormRadioButton)`
  width: 100%;
  flex-basis: 50%;
  margin-top: 0;
`
