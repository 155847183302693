import React, { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { H1, H2, TabBar } from '@chordco/component-library'
import { Explore } from 'components/looker/Explore'
import { useIsMobile, useAnalyticsTabsHelper } from 'hooks'
import { useLookerData } from 'redux/state/looker'
import { Hr } from '../common'
import { BrowserWarning } from './BrowserWarning'
import { NavigationContext } from 'components/AuthenticatedRoutes'
import { MyFolder } from 'components/looker/MyFolder'
import { useIsLoading } from 'redux/utils'
import { AddNewDashboard } from 'components/looker/AddNewDashboard'
import useMetricSlug from 'hooks/useMetricSlug'
import { Reports } from './reports/Reports'
import { getReportTabs } from './tabs'
import LookerStatus, { LookerStatusType } from './LookerStatus'

export interface AnalyticsFeatureFlags {
  showMyFolder: boolean
  showLookerUnderReview: boolean
  showLookerIsSample: boolean
  showLookerUnconfigured: boolean
  showSetDefaultDashboard: boolean
}

interface AnalyticsProps {
  featureFlags: AnalyticsFeatureFlags
}

export const Analytics: React.FC<AnalyticsProps> = ({ featureFlags }) => {
  const {
    showMyFolder,
    showLookerUnderReview,
    showLookerIsSample,
    showLookerUnconfigured,
    showSetDefaultDashboard,
  } = featureFlags

  const history = useHistory()
  const isMobile = useIsMobile()
  const Heading = isMobile ? H2 : H1

  const {
    getAvailableUserReports,
    state: { availableReports, availableUserReports },
  } = useLookerData()

  const tabs = useMemo(() => getReportTabs(showMyFolder), [showMyFolder])

  const [selectedTabId, setSelectedTabId] = useAnalyticsTabsHelper(tabs)

  const { isExplores, isUserDashboards, isReports } = useMemo(() => {
    const path = history.location.pathname
    return {
      isExplores: path.includes('explores'),
      isUserDashboards: path.includes('my_folder'),
      isReports: path.includes('reports'),
    }
  }, [history.location.pathname])

  // This hook will retrieve the slug for the current metric. If the slug cannot be found
  // in the path, it will default to the first available metric for the current active tab.
  const slug = useMetricSlug(
    isExplores,
    isUserDashboards,
    availableReports,
    availableUserReports
  )

  const isRefreshingUserReports = useIsLoading('getAvailableUserReports')

  const handleDeletedDashboard = async () => {
    if (getAvailableUserReports) {
      getAvailableUserReports().then(() => {
        history.push('/analytics/my_folder')
      })
    }
  }

  const handlePublishedDashboard = async () => {
    history.push('/analytics/reports')
  }

  // Refresh user reports when the user navigates to the My Folder tab
  useEffect(() => {
    if (isUserDashboards && getAvailableUserReports) {
      getAvailableUserReports()
    }
  }, [isUserDashboards])

  return (
    <NavigationContext.Consumer>
      {({ navOpen }) => (
        <Wrapper navOpen={navOpen}>
          <Header>
            <Heading>Analytics</Heading>
            <TabBar
              tabs={tabs}
              selectedId={selectedTabId}
              setSelectedId={setSelectedTabId}
              resetOnInvalidSelection
            />
            <Hr isMobile={isMobile} />

            <BrowserWarning />
          </Header>

          {showLookerUnconfigured && (
            <LookerStatus status={LookerStatusType.Unconfigured} />
          )}

          {showLookerUnderReview && (
            <LookerStatus status={LookerStatusType.UnderReview} />
          )}

          {showLookerIsSample && (
            <LookerStatus status={LookerStatusType.IsSample} />
          )}

          {!showLookerUnconfigured && !showLookerUnderReview && (
            <EmbedContainer>
              {isExplores && (
                <Explore slug={slug} options={availableReports.explores} />
              )}
              {isReports && <Reports reports={availableReports.dashboards} />}
              {isUserDashboards &&
                !isRefreshingUserReports &&
                (slug ? (
                  <MyFolder
                    slug={slug}
                    options={availableUserReports.userDashboards}
                    onDeletedDashboard={handleDeletedDashboard}
                    onPublishedDashboard={handlePublishedDashboard}
                    setDefaultEnabled={showSetDefaultDashboard}
                  />
                ) : (
                  <AddNewDashboard />
                ))}
            </EmbedContainer>
          )}
        </Wrapper>
      )}
    </NavigationContext.Consumer>
  )
}

const Wrapper = styled.div<{ navOpen: boolean }>`
  color: ${p => p.theme.ContentPrimary};
  height: 100vh;
  width: ${p => (p.navOpen ? 'calc(100vw - 280px)' : '100vw')};
  position: fixed;
  top: 0;
  right: 0;
`

const Header = styled.div`
  padding: 24px 32px 0px 64px;
`

const EmbedContainer = styled.div`
  width: 100%;
  height: calc(100vh - 90px);
`
