import useMixpanel from 'hooks/useMixpanel'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

interface PageViewTrackerProps {
  children?: React.ReactNode
}

const PageViewTracker: React.FC<PageViewTrackerProps> = ({ children }) => {
  const history = useHistory()

  const { trackPageView } = useMixpanel()

  useEffect(() => {
    // To track the first pageview upon load
    trackPageView()

    // To track the subsequent pageviews
    history.listen(trackPageView)
  }, [history])

  return <React.Fragment>{children}</React.Fragment>
}

export default PageViewTracker
