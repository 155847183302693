import React, { useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import {
  DefaultControl,
  Dropdown,
  standardIcons,
  AlertModal,
  Button,
} from '@chordco/component-library'
import { Loading } from 'components/common'
import { ExploreEmbed } from './ExploreEmbed'
import useEmbedUrl from './useEmbedUrl'
import { AvailableReport } from 'types'

type Props = {
  slug?: string
  options: AvailableReport[]
}

const getLinkFromSlug = (slug: string) => `/analytics/explores/${slug}`

const { AlertInfo } = standardIcons

type ModalProps = {
  onClose: () => void
  title: string
  content: string
  link?: {
    text: string
    href: string
  }
}

const ExploresModal: React.FC<ModalProps> = ({
  onClose,
  title,
  content,
  link,
}) => (
  <AlertModal
    onClose={onClose}
    title={title}
    content={content}
    link={link}
    icon={AlertInfo}
    width="482px"
    buttons={[
      {
        id: 1,
        text: 'Close',
        onClick: onClose,
        purpose: 'primary',
      },
    ]}
    centered
  />
)

export const Explore: React.FC<Props> = ({ slug, options }) => {
  const dropdownOptions = options.map(o => ({
    id: o.slug,
    label: o.label,
  }))

  const selectedOption = !slug ? options[0] : options.find(o => o.slug === slug)

  if (!selectedOption) return null

  const history = useHistory()
  const onSelect = (slug: string) => history.push(getLinkFromSlug(slug))
  const selectedValue = selectedOption.label

  const [showInfoModal, setShowInfoModal] = useState(false)

  const { embedUrls, isLoading } = useEmbedUrl(selectedOption.slug, 'explore')

  return (
    <Wrapper>
      <Top>
        <div>
          <Dropdown
            options={dropdownOptions}
            onSelect={onSelect}
            selected={slug}
            control={
              <DefaultControl value={selectedValue} customWidth="198px" />
            }
          />
        </div>

        <Right>
          <Button
            size="small"
            purpose="tertiary"
            onClick={() => setShowInfoModal(true)}
          >
            What are Explores?
          </Button>
        </Right>
      </Top>
      <EmbedContainer>
        {isLoading ? (
          <Loading />
        ) : (
          <ExploreEmbed url={embedUrls[selectedOption.slug]} />
        )}
      </EmbedContainer>

      {showInfoModal && (
        <ExploresModal
          onClose={() => setShowInfoModal(false)}
          title="What are Explores?"
          content="Explores help you navigate data tables. Each one is a collection of measures and dimensions that can be dragged and dropped as columns, pivots, or filters to create custom reports and visualizations."
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100vh;
  padding: 0 64px 0 64px;
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
`

const Right = styled.div`
  display: flex;
  align-items: center;
`

const EmbedContainer = styled.div`
  border-radius: 0 0 0 ${p => p.theme.PageRadius};
  border-top: 1px solid ${p => p.theme.BorderOptimal};
  height: 100%;
`
