import { useEffect, useRef } from 'react'
import { useMediaQuery } from 'react-responsive'

export * from './useExpandCollapseAnimation'

export const useIsMobile = (): boolean =>
  useMediaQuery({ query: '(max-width: 580px)' })

export const useClickOutsideWithRef = (
  ref: any,
  onClickOutside: Function,
  dependencies: any[] = []
) => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        ref.current &&
        document.contains(event.target) &&
        !ref.current.contains(event.target)
      ) {
        event.stopPropagation()
        onClickOutside()
      }
    }

    setTimeout(() => {
      document.addEventListener('click', handleClickOutside)
    }, 0)
    return () => document.removeEventListener('click', handleClickOutside)
  }, [ref, onClickOutside, ...dependencies])

  return ref
}

export const useClickOutside = (
  onClickOutside: Function,
  dependencies: any[] = []
) => {
  const ref = useRef() as any
  return useClickOutsideWithRef(ref, onClickOutside, dependencies)
}

export const useClickOutsideMultiple = (
  refs: any[],
  onClickOutside: Function,
  dependencies: any[] = []
) => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      let clickedOutside = true
      refs.forEach(ref => {
        if (
          ref.current &&
          document.contains(event.target) &&
          ref.current.contains(event.target)
        ) {
          clickedOutside = false
        }
      })

      if (clickedOutside) {
        event.stopPropagation()
        onClickOutside()
      }
    }

    document.addEventListener('click', handleClickOutside)
    return () => document.removeEventListener('click', handleClickOutside)
  }, [...dependencies])
}

export const useUploadImage = (e: any, saveFile: Function) => {
  if (e.target.files[0].size > 1000000) return false

  const reader = new FileReader()
  reader.onload = (e: any) => saveFile(e.target.result)
  reader.readAsDataURL(e.target.files[0])
  return true
}
