import React from 'react'
import styled from 'styled-components'
import { Button, Label, Modal } from '@chordco/component-library'
import { FormInput, FormTextArea } from 'components/common'
import { useActivateAudience } from 'components/looker/useActivateAudience'
import { useForm } from 'hooks'

interface ActivateAudienceModalProps {
  audienceName: string
  audienceId: string
  onClose: () => void
  onActivate: () => void
}

export const ActivateAudienceModal: React.FC<ActivateAudienceModalProps> = ({
  audienceName,
  audienceId,
  onClose,
  onActivate,
}) => {
  const { submit, isLoading: isSubmitting } = useActivateAudience(onActivate)

  const { fields, onSubmit } = useForm(
    {
      audienceName: {
        label: 'Name',
        type: 'string',
        initialValue: audienceName,
        required: true,
      },
      audienceDescription: {
        label: 'Description',
        type: 'string',
        initialValue: '',
        required: false,
      },
      audienceId: {
        label: 'ID',
        type: 'string',
        initialValue: audienceId,
        required: true,
      },
    },
    submit
  )

  return (
    <Modal
      onClose={onClose}
      title="Activate Audience"
      width="480px"
      closeOnClickOutside={false}
    >
      <form onSubmit={onSubmit} noValidate>
        <Section>
          <StyledLabel>
            Activate your audience to make it accessible to your entire team.
            After activation, the audience will appear in the Data Activations
            section when setting up a new Sync.
          </StyledLabel>
          <FormInput field={fields.audienceName} customWidth="100%" required />
          <FormTextArea field={fields.audienceDescription} customWidth="100%" />
        </Section>

        <BottomSection>
          <Button purpose="tertiary" onClick={onClose}>
            Cancel
          </Button>

          <Button type="submit" purpose="primary" isLoading={isSubmitting}>
            Activate
          </Button>
        </BottomSection>
      </form>
    </Modal>
  )
}

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledLabel = styled(Label)`
  white-space: pre-wrap;
`

const BottomSection = styled.div`
  width: 100%;
  padding: 16px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`
