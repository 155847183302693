import React, { useEffect, useMemo } from 'react'
import { FormInput, FlexDiv, Bottom, Form, FormDropdown } from '../common'
import { useForm } from 'hooks'
import { User } from 'types'
import { useNotifications } from 'redux/state/notifications'
import { useUsersData } from 'redux/state/users'
import { v4 as generateUUID } from 'uuid'
import { useIsLoading } from 'redux/utils'
import { Button, Sheet, standardIcons } from '@chordco/component-library'
import styled from 'styled-components'
import { useRolePermissionDescriptionsData } from 'redux/state/rolePermissionDescriptions'
import { RolePermissions } from './RolePermissions'

type Props = {
  onClose: () => void
  user: User
  canEdit?: boolean
  isSuperAdmin?: boolean
  isTeamAdmin?: boolean
}
const { Trash } = standardIcons

export const EditUser: React.FC<Props> = ({
  onClose,
  user,
  canEdit,
  isSuperAdmin,
  isTeamAdmin,
}) => {
  const { deleteUser, updateUser } = useUsersData()

  const submit = async ({ firstName, lastName, email, role }) => {
    const userRole = isTeamAdmin || isSuperAdmin ? role : 'no_role'
    await updateUser({
      id: user.id,
      firstName,
      lastName,
      email,
      role: userRole,
    })
    onClose()
  }

  const onDeleteClick = async (id: number) => {
    await deleteUser(id)
    onClose()
  }

  const { fields, onSubmit } = useForm(
    {
      firstName: {
        label: 'First Name',
        type: 'string',
        required: true,
        initialValue: user?.firstName,
      },
      lastName: {
        label: 'Last Name',
        type: 'string',
        required: true,
        initialValue: user?.lastName,
      },
      email: {
        label: 'Email',
        type: 'email',
        required: true,
        initialValue: user?.email,
      },
      role: {
        label: 'Role',
        type: 'string',
        initialValue: user?.role || 'admin',
        required: false,
      },
    },
    submit
  )

  const { addNotification } = useNotifications()

  const showUnauthorizedMessage = e => {
    e.preventDefault()
    addNotification({
      id: generateUUID(),
      message: 'You are not authorized to edit this user',
      type: 'caution',
    })
  }

  const isLoading = useIsLoading(
    'updateUser',
    'deleteUser',
    'getRolePermissionDescriptions'
  )

  const { state: roles, getRolePermissionDescriptions } =
    useRolePermissionDescriptionsData()

  const isFetchingRolePermissionDescriptions = useIsLoading(
    'getRolePermissionDescriptions'
  )

  useEffect(() => {
    if (
      !roles.rolePermissionDescriptions.length &&
      !isFetchingRolePermissionDescriptions
    ) {
      getRolePermissionDescriptions()
    }
  }, [roles.rolePermissionDescriptions.length, getRolePermissionDescriptions])

  const filteredPermissions = useMemo(() => {
    return roles.rolePermissionDescriptions
      .filter(permission => isSuperAdmin || permission.role !== 'superuser')
      .map(permission => ({
        id: permission.role,
        label: permission.label,
      }))
  }, [roles.rolePermissionDescriptions])

  return (
    <Sheet
      title="Edit User"
      onClose={onClose}
      isLoading={isLoading}
      customWidth=""
    >
      <Form onSubmit={canEdit ? onSubmit : showUnauthorizedMessage}>
        <Row>
          <FormInput field={fields.firstName} customWidth="100%" />
          <FormInput field={fields.lastName} customWidth="100%" />
        </Row>
        <FormInput
          field={fields.email}
          disabled
          tooltip="If you need to change the email address, please contact support at help@chord.co"
          customWidth="100%"
        />

        {(isTeamAdmin || isSuperAdmin) && (
          <FormDropdown
            field={{ ...fields.role }}
            options={filteredPermissions}
            customWidth="100%"
          />
        )}

        {!isFetchingRolePermissionDescriptions &&
          roles.rolePermissionDescriptions &&
          (isTeamAdmin || isSuperAdmin) && (
            <RolePermissions
              roles={roles.rolePermissionDescriptions}
              selectedRole={fields.role.value.toString()}
            />
          )}
        <Bottom>
          <FlexDiv>
            {(isTeamAdmin || isSuperAdmin) && (
              <DeleteButtonContainer>
                <Button
                  onClick={() => onDeleteClick(user.id)}
                  icon={Trash}
                  purpose="delete-secondary"
                />
              </DeleteButtonContainer>
            )}
            <Button type="submit" purpose="primary">
              Save Changes
            </Button>
          </FlexDiv>
        </Bottom>
      </Form>
    </Sheet>
  )
}

const Row = styled.div`
  display: flex;
  gap: 8px;
  > * {
    flex: 1;
  }
`

const DeleteButtonContainer = styled.span`
  padding-right: 10px;
  width: 15%;
`
