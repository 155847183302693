import { AnalyticsTab } from 'hooks'

export const getReportTabs = (showMyFolder: boolean): AnalyticsTab[] => [
  ...(showMyFolder
    ? [
        {
          id: 1,
          slug: 'my_folder',
          title: 'My Folder',
          link: '/analytics/my_folder',
        },
      ]
    : []),
  {
    id: 2,
    slug: 'reports',
    title: 'Reports',
    link: '/analytics/reports',
  },
  {
    id: 3,
    slug: 'explores',
    title: 'Explores',
    link: '/analytics/explores',
  },
]

export const getAudienceTabs = (showMyAudiences: boolean): AnalyticsTab[] => [
  ...(showMyAudiences
    ? [
        {
          id: 1,
          slug: 'my-audiences',
          title: 'My Audiences',
          link: '/audiences/my-audiences',
        },
      ]
    : []),
  {
    id: 2,
    slug: 'prebuilt-audiences',
    title: 'Prebuilt Audiences',
    link: '/audiences/prebuilt-audiences',
  },
]
