import { useEverySucceeded, useIsLoading } from 'redux/utils'
import { useLookerData } from 'redux/state/looker'
import { useEffect } from 'react'

interface PublishDashboardData {
  dashboardName: string
  dashboardDescription: string
  dashboardSlug: string
}

export const usePublishDashboard = (onPublish: () => void) => {
  const { publishDashboard } = useLookerData()

  const isLoading = useIsLoading('publishDashboard')
  const hasSucceeded = useEverySucceeded('publishDashboard')

  const submit = async ({
    dashboardName,
    dashboardDescription,
    dashboardSlug,
  }: PublishDashboardData) => {
    if (!publishDashboard) return

    await publishDashboard(dashboardName, dashboardDescription, dashboardSlug)
  }

  useEffect(() => {
    if (hasSucceeded) {
      onPublish()
    }
  }, [hasSucceeded, onPublish])

  return { submit, isLoading }
}
