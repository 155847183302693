import React, { useState } from 'react'
import { useForm } from 'hooks'
import { FormCheckbox, FormSecureInput } from 'components/common'
import { Button, Modal } from '@chordco/component-library'
import styled from 'styled-components'
import { useCreateConfigForm } from 'redux/hooks/useCreateConfigForm'
import { CreateConfigConfirmation } from './CreateConfigConfirmation'
import { ChordConfigSheet } from 'types'
import createConfigSchema from './CreateConfigSchema'

type Props = {
  onClose: () => void
  configSheets: ChordConfigSheet[]
}

enum ConfigFormSections {
  InputConfig = 'input_config',
  ConfirmConfig = 'confirm_config',
}

export const CreateConfigForm: React.FC<Props> = ({
  onClose,
  configSheets,
}) => {
  const [section, setSection] = useState(ConfigFormSections.InputConfig)

  const sectionTransitions = {
    [ConfigFormSections.InputConfig]: {
      next: () => ConfigFormSections.ConfirmConfig,
      prev: () => ConfigFormSections.InputConfig,
    },
    [ConfigFormSections.ConfirmConfig]: {
      prev: () => ConfigFormSections.InputConfig,
    },
  }

  const navigateSection = direction => {
    const transition = sectionTransitions[section][direction]
    if (transition) {
      setSection(transition)
    }
  }

  const goToNextSection = () => navigateSection('next')
  const goToPreviousSection = () => navigateSection('prev')

  const { submit, isLoading } = useCreateConfigForm(onClose)

  const configSchema = createConfigSchema()
  const { fields, onSubmit } = useForm(configSchema, submit)

  const isValid =
    fields.spreadsheetId.value &&
    (fields.sheets.value as Array<ChordConfigSheet>).length > 0

  return (
    <Modal
      onClose={onClose}
      title="Create Configurations"
      width="540px"
      closeOnClickOutside={false}
    >
      <form onSubmit={onSubmit} noValidate>
        <Container>
          {section === ConfigFormSections.InputConfig && (
            <Section>
              <Label>
                Enter the spreadsheet ID and select the sheets to be used for
                this tenant.
              </Label>

              <FormSecureInput
                field={fields.spreadsheetId}
                customWidth="100%"
                required
              />

              <CheckboxListContainer>
                <Label>Please select one or more supported sheets:</Label>
                <CheckboxList>
                  {configSheets.map(sheet => (
                    <StyledCheckbox
                      key={sheet.slug}
                      field={{
                        name: 'Sheet',
                        label: sheet.name,
                        type: 'boolean',
                        value: (
                          fields.sheets.value as Array<ChordConfigSheet>
                        ).includes(sheet),
                        setValue: val => {
                          const currentSheets = fields.sheets
                            .value as Array<ChordConfigSheet>

                          const updatedSheets = val
                            ? [...currentSheets, sheet]
                            : currentSheets.filter(s => s !== sheet)

                          fields.sheets.setValue(updatedSheets)
                        },
                      }}
                    />
                  ))}
                </CheckboxList>
              </CheckboxListContainer>
            </Section>
          )}

          {section === ConfigFormSections.ConfirmConfig && (
            <CreateConfigConfirmation fields={fields} />
          )}
        </Container>

        <Bottom>
          {section === ConfigFormSections.InputConfig && (
            <>
              <Button onClick={onClose} purpose="secondary">
                Cancel
              </Button>

              <Button
                variant="tertiary"
                onClick={goToNextSection}
                disabled={!isValid}
              >
                Next
              </Button>
            </>
          )}

          {section === ConfigFormSections.ConfirmConfig && (
            <>
              <Button variant="tertiary" onClick={goToPreviousSection}>
                Back
              </Button>

              <Button
                type="submit"
                purpose="primary"
                isLoading={isLoading}
                disabled={isLoading}
              >
                Create Config
              </Button>
            </>
          )}
        </Bottom>
      </form>
    </Modal>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 24px;
  margin-bottom: 70px;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Label = styled.div`
  margin-bottom: 4px;
  color: ${p => p.theme.ContentSecondary};
  white-space: pre-wrap;
`

const Bottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${p => p.theme.ComponentWorkspaceBgPage};
  border-top: 1px solid ${p => p.theme.BorderHairline};
  padding: 16px;
  z-index: 1;
  height: 70px;
  border-radius: 0 0 8px 8px;
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

const CheckboxListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 8px;
`

const CheckboxList = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 10px;
`

const StyledCheckbox = styled(FormCheckbox)`
  width: 100%;
  flex-basis: 50%;
  margin-top: 0;
`
