import {
  NavItemType,
  standardIcons,
  SubNavItemType,
} from '@chordco/component-library'
import { ContentfulNavIcon } from 'assets/ContentfulNavIcon'
import { SegmentNavIcon } from 'assets/SegmentNavIcon'
import { Plan } from 'types'

const allPlans = Object.values(Plan)

/**
 * A dedicated set of links that we use when we are presenting an Autonomy store embedded in iframes.
 */
const embeddedAutonomySettings: SubNavItemType[] = [
  {
    id: 101,
    title: 'Store Setup',
    link: '/settings/store-setup',
    showForPlans: [Plan.Autonomy],
    slug: 'oms-settings-store-setup',
  },
  {
    id: 102,
    title: 'Payments',
    link: '/settings/payments',
    showForPlans: [Plan.Autonomy],
    slug: 'oms-settings-payments',
  },
  {
    id: 103,
    title: 'Taxes',
    link: '/settings/taxes',
    showForPlans: [Plan.Autonomy],
    slug: 'oms-settings-taxes',
  },
  {
    id: 104,
    title: 'Refunds and Returns',
    link: '/settings/refunds',
    showForPlans: [Plan.Autonomy],
    slug: 'oms-settings-refunds',
  },
  {
    id: 105,
    title: 'Shipping',
    link: '/settings/shipping',
    showForPlans: [Plan.Autonomy],
    slug: 'oms-settings-shipping',
  },
  {
    id: 106,
    title: 'Zones',
    link: '/settings/zones',
    showForPlans: [Plan.Autonomy],
    slug: 'oms-settings-zones',
  },
]

const storeNavLinks: NavItemType[] = [
  {
    id: 1,
    title: 'Home',
    icon: standardIcons.Home,
    link: '/',
    slug: 'store-nav-home',
    showForPlans: allPlans,
  },
  {
    id: 2,
    title: 'Orders',
    icon: standardIcons.Cart,
    link: '/orders',
    showForPlans: [Plan.Autonomy],
    slug: 'store-nav-orders',
  },
  // {
  //   id: 3,
  //   title: 'Batch Orders',
  //   icon: standardIcons.Template,
  //   link: '/batchOrders',
  //   showForPlans: [Plan.Autonomy],
  //   blockedReleases?: ['prerelease', 'sales'],
  //   slug: 'store-nav-batch-orders',
  // },
  {
    id: 4,
    title: 'Promotions',
    icon: standardIcons.Tag,
    link: '/promotions',
    showForPlans: [Plan.Autonomy],
    slug: 'store-nav-promotions',
  },
  {
    id: 5,
    title: 'Subscriptions',
    icon: standardIcons.Past,
    link: '/subscriptions',
    showForPlans: [Plan.Autonomy],
    slug: 'store-nav-subscriptions',
  },
  {
    id: 6,
    title: 'Products',
    icon: standardIcons.Table,
    link: '/products',
    showForPlans: [Plan.Autonomy],
    slug: 'store-nav-products',
  },
  {
    id: 7,
    title: 'Stock',
    icon: standardIcons.Clipboard,
    link: '/stock',
    showForPlans: [Plan.Autonomy],
    slug: 'store-nav-stock',
  },
  {
    id: 8,
    title: 'Customers',
    icon: standardIcons.Group,
    link: '/customers',
    showForPlans: [Plan.Autonomy],
    slug: 'store-nav-customers',
  },
  {
    id: 9,
    title: 'Products & Content',
    icon: ContentfulNavIcon,
    link: 'https://be.contentful.com/login',
    openOutsideLink: true,
    showForPlans: [Plan.Autonomy, Plan.Performance],
    slug: 'store-nav-contentful',
  },
  {
    id: 10,
    title: 'Settings',
    icon: standardIcons.Gear,
    link: '/settings',
    subItems: embeddedAutonomySettings,
    showForPlans: [Plan.Autonomy],
    slug: 'store-nav-settings',
  },
]

const hubNavLinks: NavItemType[] = [
  {
    id: 20,
    title: 'Integrations',
    icon: standardIcons.Integration,
    link: '/integrations',
    slug: 'hub-nav-integrations',
    showForPlans: [Plan.Autonomy, Plan.Performance, Plan.DataOnly],
  },
  {
    id: 21,
    title: 'Team',
    icon: standardIcons.AddUser,
    link: '/users',
    slug: 'hub-nav-users',
    showForPlans: allPlans,
  },
  {
    id: 22,
    title: 'System Health',
    icon: standardIcons.BarChart,
    link: '/system-health',
    slug: 'hub-nav-system-health',
    showForPlans: allPlans,
  },
  {
    id: 23,
    title: 'CDP',
    icon: SegmentNavIcon,
    link: 'https://app.segment.com',
    openOutsideLink: true,
    slug: 'hub-nav-segment',
    showForPlans: allPlans,
  },
  {
    id: 24,
    title: 'Documentation',
    icon: standardIcons.Encyclopedia,
    link: 'https://docs.chord.co/',
    openOutsideLink: true,
    slug: 'hub-nav-documentation',
    showForPlans: allPlans,
  },
]

const dataNavLinks: NavItemType[] = [
  {
    id: 30,
    title: 'Analytics',
    icon: standardIcons.LineChart,
    link: '/analytics',
    subItems: [
      {
        id: 301,
        title: 'My Folder',
        link: '/analytics/my_folder',
        slug: 'data-nav-my-folder',
        showForPlans: allPlans,
      },
      {
        id: 302,
        title: 'Reports',
        link: '/analytics/reports',
        slug: 'data-nav-reports',
        showForPlans: allPlans,
      },
      {
        id: 303,
        title: 'Explores',
        link: '/analytics/explores',
        slug: 'data-nav-explores',
        showForPlans: allPlans,
      },
    ],
    slug: 'data-nav-analytics',
    showForPlans: allPlans,
  },
  {
    id: 31,
    title: 'Data Sources',
    icon: standardIcons.DataSources,
    link: '/data-sources',
    slug: 'data-nav-sources',
    showForPlans: allPlans,
  },
  {
    id: 32,
    title: 'Data Activations',
    icon: standardIcons.DataActivations,
    link: '/data-activations',
    slug: 'data-nav-activations',
    showForPlans: allPlans,
  },
  {
    id: 33,
    title: 'Model Amplifications',
    icon: standardIcons.ModelAmplifications,
    link: '/model-amplifications',
    slug: 'data-nav-model-amplifications',
    showForPlans: allPlans,
  },
  {
    id: 34,
    title: 'Audiences',
    icon: standardIcons.PieChart,
    link: '/audiences',
    slug: 'data-nav-audiences',
    showForPlans: allPlans,
    subItems: [
      {
        id: 341,
        title: 'My Audiences',
        link: '/audiences/my-audiences',
        slug: 'data-nav-my-audiences',
        showForPlans: allPlans,
      },
      {
        id: 342,
        title: 'Prebuilt Audiences',
        link: '/audiences/prebuilt-audiences',
        slug: 'data-nav-prebuilt-audiences',
        showForPlans: allPlans,
      },
    ],
  },
]

const hiddenLinks: string[] = [
  '/environment-variables',
  '/business-errors',
  '/subscription_types',
  '/subscription_types/:id',
  '/promotion_categories',
  '/promotion_categories/:id',
  '/prepaid_subscriptions',
  '/prepaid_subscriptions/:id',
  '/integrations/:integrationId',
  '/model-amplifications',
  '/model-amplifications/:slug',
  '/analytics/my_folder/:slug',
]

const createFilterNavLinks =
  (links: NavItemType[]) => (plan: Plan, isProduction: boolean) => {
    const filteredLinks = links
      .filter(
        link =>
          link.showForPlans?.includes(plan) &&
          (isProduction || !link.productionOnly)
      )
      .map(link => ({
        ...link,
        subItems: link?.subItems?.filter(si => si.showForPlans?.includes(plan)),
      }))

    return filteredLinks
  }

const getStoreNavLinks = createFilterNavLinks(storeNavLinks)

export const getHubNavLinks = createFilterNavLinks(hubNavLinks)

export const getDataNavLinks = createFilterNavLinks(dataNavLinks)

export const getNavLinks = (currentPlan: Plan, isProduction: boolean) => ({
  storeNavLinks: getStoreNavLinks(currentPlan, isProduction),
  dataNavLinks: getDataNavLinks(currentPlan, isProduction),
  hubNavLinks: getHubNavLinks(currentPlan, isProduction),
  hiddenLinks,
})
