import React from 'react'
import {
  Button,
  standardIcons,
  useClickOutside,
} from '@chordco/component-library'
import styled from 'styled-components'
import { useAuthData } from 'redux/state/auth'
import {
  InverseThemeProvider,
  useSelectedTheme,
} from 'themes/ThemeSwitcherProvider'
import { useExperiments } from 'hooks'
import useMixpanel from 'hooks/useMixpanel'

interface Props {
  onClose: Function
  name: string
  email: string
}

export const UserMenu: React.FC<Props> = ({ onClose, name, email }) => {
  const { logout } = useAuthData()
  const { resetUser } = useMixpanel()
  const { toggleTheme } = useSelectedTheme()

  const themeToggleTreatment = useExperiments('hub_theme_toggle')

  const ref = useClickOutside(onClose, [onClose])
  const {
    // Gear,
    LogOut,
  } = standardIcons

  const handleLogOut = () => {
    logout()
    resetUser()
  }

  return (
    <InverseThemeProvider>
      <Container ref={ref}>
        <div>
          <Name>{name}</Name>
          <Email>{email}</Email>
        </div>
        {/*<SettingsButton icon={Gear} purpose="ghost" />*/}

        {themeToggleTreatment?.enabled && (
          <div>
            <Button
              variant="tertiary"
              color="neutral"
              size="tiny"
              onClick={toggleTheme}
            >
              Toggle Theme
            </Button>
          </div>
        )}

        <LogOutContainer>
          <Button purpose="ghost" icon={LogOut} onClick={handleLogOut}>
            Log out
          </Button>
        </LogOutContainer>
      </Container>
    </InverseThemeProvider>
  )
}

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  padding: 16px;
  top: 64px;
  left: 16px;
  height: auto;
  width: 248px;
  background-color: ${p => p.theme.BgBase1};
  border: solid 0px ${p => p.theme.BorderHairline};
  border-radius: 8px;
  z-index: 1;
`

const Name = styled.div`
  // position: absolute;
  top: 16px;
  left: 16px;
  color: ${p => p.theme.ContentPrimary};
`

const Email = styled.div`
  // position: absolute;
  top: 40px;
  left: 16px;
  font-size: 12px;
  color: ${p => p.theme.ContentSecondary};
`

// const SettingsButton = styled(Icon)`
//   position: absolute;
//   top: 16px;
//   right: 16px;
// `

const LogOutContainer = styled.div`
  // position: absolute;
  bottom: 0;
  // height: 56px;
  // width: 100%;
  padding-top: 8px;
  border-top: solid 1px ${p => p.theme.BorderHairline};

  button {
    //width: 246px;
    text-align: left;
  }
`
