import type { Location } from 'history'
import { formatBaseURL } from 'utils/urlUtils'

/**
 * Returns the OMS URL for a given embedded OMS page.
 */
function getOmsPageUrl(location: Location, baseUrl: string) {
  const hubToOmsPageMap = {
    products: 'products',
    orders: 'orders',
    promotions: 'promotions',
    promotion_categories: 'promotion_categories',
    subscriptions: 'subscriptions',
    subscription_types: 'subscription_types',
    prepaid_subscriptions: 'pre_paid_subscriptions',
    stock: 'stock_items',
    customers: 'users',
    'settings/store-setup': `stores`,
    'settings/payments': 'payment_methods',
    'settings/taxes': 'tax_categories',
    'settings/refunds': 'refund_reasons',
    'settings/shipping': 'shipping_methods',
    'settings/zones': 'zones',
  }

  const hubPage = location.pathname.slice(1)

  if (hubPage.startsWith('settings')) {
    const omsPagePath = hubToOmsPageMap[hubPage]
    const url = new URL(`${baseUrl}/admin/${omsPagePath}`)
    return addAuthTokenToURL(url)
  }

  // Check if there's an identifier in the hubPage
  // See regex testbed located at https://regex101.com/r/Qi7D0K/1
  // Can be numeric like `/promotions/1` or alphanumeric like `/orders/abc-123`
  const regex = /\/(\w+(?:-?\w+)*)/
  const identifierMatch = hubPage.match(regex)

  if (identifierMatch) {
    // If an identifier is present, extract it and use the rest of the path
    const identifier = identifierMatch[0]
    const baseHubPage = hubPage.replace(identifier, '')
    const omsPagePath = hubToOmsPageMap[baseHubPage]

    if (omsPagePath) {
      const url = new URL(`${baseUrl}/admin/${omsPagePath}${identifier}`)
      return addAuthTokenToURL(url)
    } else {
      throw new Error(`No mapping found for hub page: ${baseHubPage}`)
    }
  } else {
    const omsPagePath = hubToOmsPageMap[hubPage]

    if (omsPagePath) {
      // Use the mapped path for hubPage
      const url = new URL(`${baseUrl}/admin/${omsPagePath}`)
      return addAuthTokenToURL(url)
    } else {
      throw new Error(`No mapping found for hub page: ${hubPage}`)
    }
  }
}

function getEmbeddedOmsUrl(location: Location, baseUrl: string) {
  const formattedBaseUrl = formatBaseURL(baseUrl)

  return getOmsPageUrl(location, formattedBaseUrl)
}

/**
 * Generates an authenticated OMS URL for a given OMS embedded page path.
 */
function addAuthTokenToURL(url: URL) {
  const sessionToken = localStorage.getItem('session_token')
  const b2b = localStorage.getItem('b2b')

  if (!sessionToken) {
    // eslint-disable-next-line no-console
    console.error('Missing CSRF session token!')
  }

  const params = new URLSearchParams({
    auth_token: encodeURIComponent(sessionToken ?? ''),
    b2b: encodeURIComponent(b2b ?? ''),
  })

  url.search = params.toString()

  return url
}

export { getEmbeddedOmsUrl }
