import { useHistory } from 'react-router'

export const useEmbeddedOmsPage = () => {
  const history = useHistory()

  // This is a list of OMS pages that should be embedded within the Hub.
  // We use regex to match the path because some of the pages have dynamic
  // segments (e.g. /orders/:id).
  const pagestoEmbed = [
    /^\/products(\/[\w-]+)?$/,
    /^\/orders(\/[\w-]+)?$/,
    /^\/promotions(\/\d+)?$/,
    /^\/promotion_categories(\/\d+)?$/,
    /^\/subscriptions(\/\d+)?$/,
    /^\/subscription_types(\/\d+)?$/,
    /^\/pre_paid_subscriptions(\/\d+)?$/,
    /^\/customers(\/\d+)?$/,
    /^\/stock$/,
    /^\/settings\/store-setup$/,
    /^\/settings\/payments$/,
    /^\/settings\/taxes$/,
    /^\/settings\/refunds$/,
    /^\/settings\/shipping$/,
    /^\/settings\/zones$/,
  ]

  return pagestoEmbed.some(regex => regex.test(history.location.pathname))
}
