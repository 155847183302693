import { NavItemType } from '@chordco/component-library'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const useNavSelection = (
  storeNavLinks: NavItemType[],
  dataNavLinks: NavItemType[],
  hubNavLinks: NavItemType[],
  hiddenLinks: string[],
  setSelectedNavId: (id: number | undefined) => void,
  currentStoreIsAutonomy: boolean,
  isProduction: boolean
) => {
  const history = useHistory()

  const getAllLinks = () => [...storeNavLinks, ...dataNavLinks, ...hubNavLinks]

  const findExactMatch = (path: string, allLinks: NavItemType[]) => {
    let linkId: number | undefined

    allLinks.forEach(navLink => {
      if (path === navLink.link) linkId = navLink.id

      const subLinkId = navLink.subItems?.find(si => path === si.link)?.id
      if (subLinkId) linkId = subLinkId

      const subSubLinkId = navLink.subItems
        ?.find(si => si.subItems?.find(ssi => path === ssi.link))
        ?.subItems?.find(ssi => path === ssi.link)?.id
      if (subSubLinkId) linkId = subSubLinkId
    })

    return linkId
  }

  const findSubNavMatch = (path: string, allLinks: NavItemType[]) =>
    allLinks
      .map(l => l.subItems)
      .flat()
      .find(l => l?.link && path.startsWith(l.link))

  const findStartsWithMatch = (path: string, allLinks: NavItemType[]) =>
    allLinks
      .filter(l => l.link !== '/') // `segment` will always start with this!
      .find(l => l.link && path.startsWith(l.link))

  const isPathContained = (path, array) => {
    const exactMatch = array.includes(path)
    if (exactMatch) {
      return true
    }

    // Support dynamic OMS iframe paths (e.g /promotion_categories/:id)
    const dynamicMatch = array.some(item => {
      // Match '/:id' and replace it with a regex pattern that
      // matches a number (e.g /promotion_categories/1)
      const regexPattern = item.replace(/\/:(\w+)/, '(?:\\/([\\d+]+))')

      // Create a regex to match the entire path
      const regex = new RegExp(`^${regexPattern}$`)

      return regex.test(path)
    })

    return dynamicMatch
  }

  const handlePathSelection = (path: string) => {
    const allLinks = getAllLinks()

    if (!allLinks.length) return

    const linkId = findExactMatch(path, allLinks)
    if (linkId) {
      setSelectedNavId(linkId)
      return
    }

    const subNavMatch = findSubNavMatch(path, allLinks)
    if (subNavMatch) {
      setSelectedNavId(subNavMatch.id)
      return
    }

    const startsWithMatch = findStartsWithMatch(path, allLinks)
    if (startsWithMatch) {
      setSelectedNavId(startsWithMatch.id)
      return
    }

    if (isPathContained(path, hiddenLinks)) {
      setSelectedNavId(undefined)
      return
    }

    history.push('/')
  }

  useEffect(() => {
    const path = window.location.pathname
    handlePathSelection(path)
  }, [
    history.location,
    currentStoreIsAutonomy,
    isProduction,
    storeNavLinks,
    dataNavLinks,
    hubNavLinks,
  ])
}

export default useNavSelection
