import { Dropdown, DefaultControl, Label } from '@chordco/component-library'
import React from 'react'
import { useReportsFilter } from 'redux/state/reportsFilter'
import styled from 'styled-components'

export const ReportToolbar: React.FC = () => {
  const {
    state: { selectedFilter },
    setFilter,
  } = useReportsFilter()

  const options = [
    { id: 'all_reports', label: 'All Reports' },
    { id: 'shared_reports', label: 'Shared Reports' },
    { id: 'chord_reports', label: 'Chord Reports' },
  ]

  const [selectedValue, setSelectedValue] = React.useState(selectedFilter)

  const onSelect = (slug: string) => {
    setSelectedValue(slug)
    setFilter(slug)
  }

  return (
    <ReportToolbarContainer>
      <FilterContainer>
        <Label>Select View</Label>
        <Dropdown
          options={options}
          selected={selectedValue}
          onSelect={onSelect}
          control={
            <DefaultControl
              value={options.find(o => o.id === selectedValue)?.label}
              customWidth="200px"
            />
          }
        />
      </FilterContainer>
    </ReportToolbarContainer>
  )
}

const ReportToolbarContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 16px;
`

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`
