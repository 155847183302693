import React from 'react'
import { Button, Modal } from '@chordco/component-library'
import { FormInput } from 'components/common'
import { FormField } from 'hooks'
import styled from 'styled-components'

interface CreateDashboardModalProps {
  fields: Record<string, FormField>
  onSubmit: React.FormEventHandler<HTMLFormElement>
  onClose: () => void
  isLoading: boolean
}

export const CreateDashboardModal: React.FC<CreateDashboardModalProps> = ({
  fields,
  onSubmit,
  onClose,
  isLoading,
}) => {
  return (
    <Modal
      onClose={onClose}
      title="Create New Report"
      width="480px"
      closeOnClickOutside={false}
    >
      <FormContainer onSubmit={onSubmit} noValidate>
        <Section>
          <FormInput field={fields.title} customWidth="100%" required />
        </Section>

        <BottomSection>
          <Button purpose="tertiary" onClick={onClose}>
            Cancel
          </Button>

          <Button
            type="submit"
            purpose="primary"
            isLoading={isLoading}
            disabled={isLoading}
          >
            Create Report
          </Button>
        </BottomSection>
      </FormContainer>
    </Modal>
  )
}

export const FormContainer = styled.form`
  overflow: auto;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const BottomSection = styled.div`
  width: 100%;
  padding: 16px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`
