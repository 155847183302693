import { useIsLoading } from 'redux/utils'
import { useLookerData } from 'redux/state/looker'
import { AvailableUserReport } from 'types'

interface CopyDashboardResponse {
  payload: {
    data: {
      userDashboards: AvailableUserReport[]
    }
    error?: string
  }
}

export const useCopyDashboard = (
  onCopied: (dashboard?: AvailableUserReport) => void
) => {
  const { copyDashboard } = useLookerData()

  const isLoading = useIsLoading('copyDashboard')

  const submit = async ({
    dashboardUrl,
    folderId,
  }: {
    dashboardUrl: string
    folderId: string
  }) => {
    if (!copyDashboard) return

    const dashboardSlug = dashboardUrl.split('/').pop() as string

    const response = await copyDashboard(dashboardSlug, folderId)

    const copyDashboardResponse = response as CopyDashboardResponse

    if (!copyDashboardResponse.payload.error) {
      const dashboard =
        copyDashboardResponse.payload.data.userDashboards[
          copyDashboardResponse.payload.data.userDashboards.length - 1
        ]
      onCopied(dashboard)
    }
  }

  return { submit, isLoading }
}
