import React from 'react'
import styled from 'styled-components'
import { v4 as generateUUID } from 'uuid'
import { useNotifications } from 'redux/state/notifications'
import { Button, Input, Label, Modal } from '@chordco/component-library'

interface ShareDashboardModalProps {
  link: string
  onClose: () => void
}

export const ShareDashboardModal: React.FC<ShareDashboardModalProps> = ({
  link,
  onClose,
}) => {
  const { addNotification } = useNotifications()

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link)

    addNotification({
      id: generateUUID() as string,
      type: 'success',
      message: 'Dashboard link successfully copied to clipboard.',
    })

    onClose()
  }

  return (
    <Modal
      onClose={onClose}
      title="Share Dashboard"
      width="480px"
      closeOnClickOutside={false}
    >
      <>
        <Section>
          <StyledLabel>
            Share this link with other Hub users to give them access to this
            dashboard. This will create a new copy of the dashboard in their
            personal folder.
          </StyledLabel>
          <Input defaultValue={link} customWidth="100%" />
        </Section>

        <BottomSection>
          <Button purpose="tertiary" onClick={onClose}>
            Cancel
          </Button>

          <Button type="submit" purpose="primary" onClick={copyToClipboard}>
            Copy Link
          </Button>
        </BottomSection>
      </>
    </Modal>
  )
}

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledLabel = styled(Label)`
  white-space: pre-wrap;
`

const BottomSection = styled.div`
  width: 100%;
  padding: 16px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`
