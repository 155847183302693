import React, { ReactNode, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Badge } from '../information'

export type TabType = {
  id: number | string
  title: string
  contentRenderer?: () => ReactNode
  number?: number
}

interface TabBarProps extends React.HTMLAttributes<HTMLDivElement> {
  tabs: TabType[]
  selectedId: number | string
  setSelectedId: Function
  isButtonTab?: boolean
  noContent?: boolean
  margin?: string
  contentPadding?: string
  resetOnInvalidSelection?: boolean
  maxWidth?: number
  padding?: string
}

export const calculateLeft = (selectedIndex: number, widths: number[]) =>
  widths.reduce((acc: number, width: number, index) => {
    return index < selectedIndex ? acc + width + 24 : acc
  }, 0)

export const TabBar: React.FC<TabBarProps> = ({
  tabs,
  selectedId,
  setSelectedId,
  noContent = false,
  margin,
  maxWidth,
  contentPadding,
  resetOnInvalidSelection = false,
  padding,
}) => {
  const [widths, setWidths] = useState<number[]>([])

  const tabsRef = useRef<HTMLDivElement>()

  const onSelection = (id: number | string) => {
    setSelectedId(id)
  }

  const onKeyDown = (e: any, id: number | string) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault()
      onSelection(id)
    }
  }

  const [fontLoaded, setFontLoaded] = useState<boolean>(false)
  useEffect(() => {
    // @ts-ignore
    document.fonts?.ready.then(() => setFontLoaded(true))
  }, [])

  useEffect(() => {
    tabs.forEach((_tab, index) => {
      const width =
        tabsRef?.current?.children[index].getBoundingClientRect().width || 0
      setWidths(([...currentWidths]: number[]) => {
        currentWidths[index] = width
        return currentWidths
      })
    })
  }, [tabs, fontLoaded])

  const selectedTab = tabs.find(({ id }) => id === selectedId)
  useEffect(() => {
    if (!selectedTab) {
      if (!tabs.length) return

      if (resetOnInvalidSelection) {
        onSelection(tabs[0].id)
        return
      }

      throw new Error('Invalid `selectedId` prop passed to `TabBar`')
    }
  }, [selectedTab, resetOnInvalidSelection, onSelection])

  if (!selectedTab) return null

  const selectedIndex = tabs.indexOf(selectedTab)
  const left = calculateLeft(selectedIndex, widths)

  const sliderWidth = widths[selectedIndex] || 0

  return (
    <Container maxWidth={maxWidth} data-testid="tab-bar" padding={padding}>
      <Bar margin={margin}>
        <Slider left={left} width={sliderWidth} />
        <TabContainer ref={tabsRef as any}>
          {tabs.map(tab => (
            <Tab
              selected={selectedId === tab.id}
              onClick={() => onSelection(tab.id)}
              onKeyDown={e => onKeyDown(e, tab.id)}
              key={tab.title}
              tabIndex={selectedId === tab.id ? -1 : 0}
            >
              <Text>
                {tab.title}
                {typeof tab.number !== 'undefined' && (
                  <StyledBadge number={tab.number} shade="light" />
                )}
              </Text>
            </Tab>
          ))}
        </TabContainer>
      </Bar>
      <Hr />

      {!noContent && selectedTab?.contentRenderer && (
        <Content contentPadding={contentPadding}>
          {selectedTab?.contentRenderer()}
        </Content>
      )}
    </Container>
  )
}

const Container = styled.div<{ maxWidth?: number; padding?: string }>`
  display: inline-block;
  overflow-y: hidden;
  white-space: nowrap;
  max-width: ${p => (p.maxWidth ? `${p.maxWidth}px` : '100%')};
  margin-bottom: -4px;
  padding: ${p => p.padding || 0};
`

const TabContainer = styled.div`
  overflow-x: auto;
  display: inline-block;

  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }

  & > div:first-child {
    margin-left: 0;
  }
`

const Hr = styled.div``

const Bar = styled.div<{ margin?: string }>`
  position: relative;
  display: flex;
  box-sizing: border-box;
  overflow-x: scroll;
  height: 28px;
  margin: ${p => p.margin || '12px 0 0 0'};

  ::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none; // Firefox

  -ms-overflow-style: none;
`

const Slider = styled.div<{ width: number; left: number }>`
  position: absolute;
  left: ${p => p.left}px;
  bottom: 0;
  width: ${p => p.width}px;
  border-bottom: 2px solid ${p => p.theme.ContentAccentPrimary};
  transition: 300ms;
`

const Tab = styled.div<{ selected: boolean }>`
  position: relative;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  transition: color 200ms;
  box-sizing: border-box;
  height: 28px;
  padding: 4px;
  margin: 0 12px;
  color: ${p =>
    p.selected ? p.theme.ContentPrimary : p.theme.ContentSecondary};
  font-size: 14px;
  font-weight: 600;

  :hover {
    color: ${p => p.theme.ContentAccentPrimary};
  }

  :focus {
    outline: none;
    border-radius: 4px;
    color: ${p => p.theme.ContentPrimary};
  }
`

const Text = styled.span`
  white-space: nowrap;
`

const StyledBadge = styled(Badge)`
  margin-left: 5px;
`

const Content = styled.div<{ contentPadding?: string }>`
  padding: ${p => p.contentPadding || '24px 0 0 0'};
`
