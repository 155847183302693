import { useEffect } from 'react'
import { getNavLinks } from 'components/navigation/links'
import { useEnvironmentsData } from 'redux/state/environments'
import { useLookerData } from 'redux/state/looker'
import { useTenantsData } from 'redux/state/tenants'
import { useEverySucceeded, useIsIdle } from '../utils'
import { useExperiments } from 'hooks'

type Filter = { section: string; slug: string; childSlug?: string }

/**
 * This function returns an array of filters to be applied to our navigation links. Please ensure
 * that `slugs` are unique and properly match the `slug` property configured in the `links.ts` file.
 */
const getNavLinksToFilter = () => {
  const filters: Filter[] = []

  const sysHealthTreatment = useExperiments('system_health')
  const showSystemHealth = sysHealthTreatment?.variation === 'on'

  // Hide the system health link if the experiment is off
  if (!showSystemHealth) {
    filters.push({ section: 'hubNavLinks', slug: 'hub-nav-system-health' })
  }

  const productsTreatment = useExperiments('hub_products_nav_link')
  const showProducts = productsTreatment?.variation === 'on'

  // Hide the products link if the experiment is off
  if (!showProducts) {
    filters.push({ section: 'storeNavLinks', slug: 'store-nav-products' })
  }

  const subscriptionsTreatment = useExperiments('hub_subscriptions_nav_link')
  const showSubscriptions = subscriptionsTreatment?.variation === 'on'

  // Hide the subscriptions link if the experiment is off
  if (!showSubscriptions) {
    filters.push({ section: 'storeNavLinks', slug: 'store-nav-subscriptions' })
  }

  const stockTreatment = useExperiments('hub_stock_nav_link')
  const showStock = stockTreatment?.variation === 'on'

  // Hide the stock link if the experiment is off
  if (!showStock) {
    filters.push({ section: 'storeNavLinks', slug: 'store-nav-stock' })
  }

  const contentfulTreatment = useExperiments('hub_contentful_nav_link')
  const showContentful = contentfulTreatment?.variation === 'on'

  // Hide the Contentful link if the experiment is off
  if (!showContentful) {
    filters.push({ section: 'storeNavLinks', slug: 'store-nav-contentful' })
  }

  const integrationsTreatment = useExperiments('hub_integrations_nav_link')
  const showIntegrations = integrationsTreatment?.variation === 'on'

  // Hide the Integrations link if the experiment is off
  if (!showIntegrations) {
    filters.push({ section: 'hubNavLinks', slug: 'hub-nav-integrations' })
  }

  const cdpTreatment = useExperiments('hub_hide_cdp_nav_link')
  const hideCdpLink = cdpTreatment?.variation === 'on'

  // Hide the CDP link if the experiment is on
  if (hideCdpLink) {
    filters.push({ section: 'hubNavLinks', slug: 'hub-nav-segment' })
  }

  const configUiTreatment = useExperiments('hub_config_ui_status')
  const enableConfigUi = configUiTreatment?.variation === 'on'

  // Hide the Config UI link if the experiment is off
  if (!enableConfigUi) {
    filters.push({
      section: 'dataNavLinks',
      slug: 'data-nav-model-amplifications',
    })
  }

  const myFolderTreatment = useExperiments('hub_my_folder_nav_link')
  const showMyFolder = myFolderTreatment?.variation === 'on'

  // Hide the My Folder (Analytics) link if the experiment is off
  if (!showMyFolder) {
    filters.push({
      section: 'dataNavLinks',
      slug: 'data-nav-analytics',
      childSlug: 'data-nav-my-folder',
    })
  }

  const myAudiencesTreatment = useExperiments('hub_my_audiences_nav_link')
  const showMyAudiences = myAudiencesTreatment?.variation === 'on'

  // Hide the My Audiences link if the experiment is off
  if (!showMyAudiences) {
    filters.push({
      section: 'dataNavLinks',
      slug: 'data-nav-audiences',
      childSlug: 'data-nav-my-audiences',
    })
  }

  return filters
}

export const useNavigationLinks = () => {
  const {
    state: { currentTenant, currentStore },
  } = useTenantsData()

  if (!currentTenant) {
    throw new Error('currentTenant is not defined')
  }

  if (!currentStore) {
    throw new Error('currentStore is not defined')
  }

  const {
    state: { selectedEnvironment },
  } = useEnvironmentsData()
  const isProduction = selectedEnvironment === 'production'

  const { getAvailableReports, getAvailableUserReports } = useLookerData()

  const getReportsIsIdle = useIsIdle('getAvailableReports')
  const getUserReportsIsIdle = useIsIdle('getAvailableUserReports')

  useEffect(() => {
    if (getAvailableReports && getReportsIsIdle) {
      getAvailableReports()
    }
  }, [getAvailableReports])

  useEffect(() => {
    if (getAvailableUserReports && getUserReportsIsIdle) {
      getAvailableUserReports()
    }
  }, [getAvailableUserReports])

  useEffect(() => {
    if (getAvailableReports && !getReportsIsIdle && currentTenant) {
      getAvailableReports()
    }

    if (getAvailableUserReports && !getUserReportsIsIdle && currentTenant) {
      getAvailableUserReports()
    }
  }, [currentTenant])

  const navLinksToFilter = getNavLinksToFilter()

  const hasSucceeded = useEverySucceeded(
    'getAvailableReports',
    'getAvailableUserReports',
    'getUserTenants'
  )
  if (!hasSucceeded) {
    return {
      storeNavLinks: [],
      dataNavLinks: [],
      hubNavLinks: [],
      hiddenLinks: [],
    }
  }

  const navLinks = getNavLinks(currentStore.plan, isProduction)

  navLinksToFilter.forEach(({ section, slug, childSlug }) => {
    if (childSlug) {
      navLinks[section] = navLinks[section].map(l => {
        if (l.slug === slug) {
          return {
            ...l,
            subItems: l.subItems?.filter(s => s.slug !== childSlug),
          }
        }

        return l
      })
    } else {
      navLinks[section] = navLinks[section].filter(l => l.slug !== slug)
    }
  })

  return navLinks
}
