import React from 'react'
import styled from 'styled-components'
import { Body, H4 } from '../type'
import { Button } from '../controls'
import { useClickOutside } from '../../hooks'
import { Overlay } from './Overlay'
import { InverseThemeProvider } from 'themes/ThemeSwitcherProvider'

interface Props {
  children?: React.ReactNode
  icon?: any
  title?: string
  content?: string
  subContent?: string
  link?: {
    text: string
    href: string
  }
  onClose: Function
  buttons?: {
    id: number
    text: string
    onClick: Function
    purpose:
      | 'primary'
      | 'secondary'
      | 'tertiary'
      | 'solid'
      | 'destructive'
      | 'ghost'
    size?: 'tiny' | 'small' | 'medium' | 'large'
    dataSelector?: string
    isLoading?: boolean
    disabled?: boolean
  }[]
  absolutePosition?: {
    top?: string
    right?: string
    bottom?: string
    left?: string
  }
  centered?: boolean
  width?: string
  padding?: string
}

export const AlertModal: React.FC<Props> = ({
  icon: Icon,
  title,
  content,
  buttons,
  absolutePosition = {},
  onClose,
  subContent,
  link,
  centered = false,
  children,
  width,
  padding,
}) => {
  const ref = useClickOutside(onClose)

  return (
    <Overlay show>
      <InverseThemeProvider>
        <Wrapper
          absolutePosition={absolutePosition}
          centered={centered}
          ref={ref}
          width={width}
          padding={padding}
        >
          {Icon && (
            <IconContainer>
              <Icon />
            </IconContainer>
          )}
          {title && (
            <Container>
              <H4>{title}</H4>
            </Container>
          )}

          {content && <Content>{content}</Content>}
          {subContent && <SubContent>{subContent}</SubContent>}
          {link && (
            <LinkContainer>
              <Link href={link.href} target="_blank">
                {link.text}
              </Link>
            </LinkContainer>
          )}
          {children && <ChildrenContainer>{children}</ChildrenContainer>}
          {buttons && (
            <ButtonContainer>
              {buttons.map(
                ({
                  id,
                  text,
                  purpose,
                  onClick,
                  size,
                  dataSelector,
                  isLoading,
                  disabled,
                }: any) => (
                  <StyledButton
                    data-selector={dataSelector}
                    key={id}
                    purpose={purpose}
                    onClick={onClick}
                    size={size}
                    isLoading={isLoading}
                    disabled={disabled}
                  >
                    {text}
                  </StyledButton>
                )
              )}
            </ButtonContainer>
          )}
        </Wrapper>
      </InverseThemeProvider>
    </Overlay>
  )
}

const Wrapper = styled.div<{
  absolutePosition: {
    top?: string
    right?: string
    bottom?: string
    left?: string
  }
  centered: boolean
  width?: string
  padding?: string
}>`
  position: ${p => (p.centered ? 'fixed' : 'absolute')};
  box-sizing: border-box;
  width: ${p => p.width || '350px'};
  padding: ${p => p.padding || '24px'};
  border-radius: ${p => p.theme.CardLargeRadius};
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${p => p.theme.ComponentWorkspaceBgPage};
  color: ${p => p.theme.ContentPrimary};
  text-align: center;
  top: ${p =>
    p.centered ? 'calc(50% - 122px)' : p.absolutePosition.top || 'auto'};
  right: ${p => p.absolutePosition.right || 'auto'};
  bottom: ${p => p.absolutePosition.bottom || 'auto'};
  left: ${p =>
    p.centered ? 'calc(50% - 168px)' : p.absolutePosition.left || 'auto'};
  z-index: 1;
`

const Container = styled.div`
  margin-bottom: 8px;
`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  border-radius: 100%;
  margin-bottom: 24px;
  box-sizing: border-box;
  background-color: ${p => p.theme.BgOverlayElevation2};
  path {
    fill: ${p => p.theme.ContentPrimary};
  }
`

const Content = styled(Body)`
  overflow: auto;
  color: ${p => p.theme.ContentPrimary};
`

const SubContent = styled.div`
  color: ${p => p.theme.ContentSecondary};
  margin-top: 8px;
`

const Link = styled.a`
  color: ${p => p.theme.ContentAccentPrimary};
`

const LinkContainer = styled.div`
  margin-top: 20px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`

const ChildrenContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`

const StyledButton = styled(Button)<{ purpose: string }>`
  margin: 0 4px;
`
