import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router'
import { useForm } from 'hooks'
import { Body, Button, H1, Spinner } from '@chordco/component-library'
import { useCreateDashboard } from './useCreateDashboard'
import { CreateDashboardModal } from './CreateDashboardModal'
import { AvailableUserReport, LookerEmbedUser } from 'types'
import { useLookerData } from 'redux/state/looker'
import { useIsLoading } from 'redux/utils'

export const AddNewDashboard: React.FC = () => {
  const history = useHistory()

  const {
    state: { embedUser },
    getEmbedUser,
    createEmbedUser,
  } = useLookerData()

  const [showAddNewModal, setShowAddNewModal] = useState(false)

  const handleAddNew = () => {
    setShowAddNewModal(true)
  }

  const onCreated = (dashboard?: AvailableUserReport) => {
    setShowAddNewModal(false)

    if (dashboard) {
      history.push(`/analytics/my_folder/${dashboard.slug}`)
    }
  }

  const { submit, isLoading } = useCreateDashboard(onCreated)

  const { fields, onSubmit } = useForm(
    {
      title: {
        label: 'Title',
        type: 'string',
        initialValue: '',
        required: true,
      },
      folderId: {
        label: 'Folder ID',
        type: 'string',
        initialValue: embedUser?.personalFolderId,
        required: true,
      },
    },
    submit
  )

  const isLoadingEmbedUser = useIsLoading('getEmbedUser')
  const isCreatingEmbedUser = useIsLoading('createEmbedUser')

  useEffect(() => {
    const fetchOrCreateEmbedUser = async () => {
      if (getEmbedUser) {
        const response = await getEmbedUser()

        const data = (response.payload as any).data as LookerEmbedUser
        if (!data) {
          if (createEmbedUser) {
            await createEmbedUser()
          }
        }
      }
    }

    fetchOrCreateEmbedUser()
  }, [])

  return (
    <Wrapper>
      <CenteredContent>
        {(isLoadingEmbedUser || isCreatingEmbedUser) && <Spinner scale={30} />}

        {!(isLoadingEmbedUser || isCreatingEmbedUser) && embedUser && (
          <>
            <StyledH1>What is My Folder?</StyledH1>
            <StyleBody>
              The My Folder folder shows your personal folder, where you can
              store reports that only you can access. You can also share content
              from your personal folder with other users.
            </StyleBody>

            <Button
              onClick={handleAddNew}
              isLoading={isLoading}
              disabled={isLoading}
            >
              Add New Report
            </Button>
          </>
        )}

        {showAddNewModal && (
          <CreateDashboardModal
            fields={fields}
            onSubmit={onSubmit}
            onClose={() => setShowAddNewModal(false)}
            isLoading={isLoading}
          />
        )}
      </CenteredContent>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const CenteredContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-top: -100px;
`

const StyledH1 = styled(H1)`
  text-align: center;
`

const StyleBody = styled(Body)`
  text-align: center;
  line-height: 1.75;
  max-width: 640px;
`
