import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

/**
 * This component listens for messages coming from OMS iframes. It ensures that the Hub React app
 * updates the browser address bar accordingly.
 *
 * For example, if we receive a message from an OMS iframe telling us that we have navigated to
 * /orders/:id/edit, we should reflect that in our browser address bar. Without this component,
 * the Hub wouldn't know that we have navigated to /orders/:id/edit, because that navigation
 * happens withing the embedded app in our iframe, and, as a result, it would never update the
 * browser address bar accordingly (i.e it would still display /orders instead of /orders/:id).
 *
 * Additionally, we remap some routes that are different in OMS and the Hub(e.g /orders/:id/edit
 * in OMS is /orders/:id in the Hub, /users/:id/edit in OMS is /customers/:id in the Hub, etc.).
 */
function IframeRouterListener({ children }) {
  const history = useHistory()

  /**
   * This should match all our OMS common routes patterns, like
   * /admin/orders, /admin/orders/abcd-1234, /admin/orders/abcd-1234/edit,
   * and the ones that have numeric identifiers, like /admin/promotions/1, etc.
   */
  function getMappingEntry(
    childRoute: string,
    parentRoute: string,
    identifierFormat: 'numeric' | 'alphanumeric-hyphens'
  ) {
    const identifierRegex =
      identifierFormat === 'alphanumeric-hyphens' ? '[\\w-]+' : '\\d+'
    const childRouteRegex = new RegExp(
      `/admin/${childRoute}(?:/(${identifierRegex}))?(?:/edit)?`
    )
    return { childRouteRegex, parentRoute }
  }

  // Map child routes to parent routes:
  // - Child routes belong to OMS iframes.
  // - Parent routes belong to the main Hub React app.
  const routeMapping = [
    getMappingEntry('promotions', '/promotions', 'numeric'),
    getMappingEntry('promotion_categories', '/promotion_categories', 'numeric'),
    getMappingEntry('subscriptions', '/subscriptions', 'numeric'),
    getMappingEntry('subscription_types', '/subscription_types', 'numeric'),
    getMappingEntry(
      'pre_paid_subscriptions',
      '/prepaid_subscriptions',
      'numeric'
    ),
    getMappingEntry('users', '/customers', 'numeric'),
    getMappingEntry('orders', '/orders', 'alphanumeric-hyphens'),
    getMappingEntry('products', '/products', 'alphanumeric-hyphens'),
  ]

  useEffect(() => {
    // Event listener for incoming messages
    const handleMessage = event => {
      if (event.data.type === 'OmsLinkClick') {
        const omsAdminRoute = event.data.route

        const routeInfo = routeMapping.find(route =>
          omsAdminRoute.match(route.childRouteRegex)
        )

        if (routeInfo) {
          const match = omsAdminRoute.match(routeInfo.childRouteRegex)
          const identifier = match ? match[1] : ''
          const matchedParentRoute = identifier
            ? `${routeInfo.parentRoute}/${identifier}`
            : routeInfo.parentRoute
          history.push(matchedParentRoute)
        }
      }
    }

    window.addEventListener('message', handleMessage)

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [history])

  return <React.Fragment>{children}</React.Fragment>
}

export default IframeRouterListener
