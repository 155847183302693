import { useIsLoading } from 'redux/utils'
import { useLookerData } from 'redux/state/looker'
import { AvailableUserReport } from 'types'

interface CreateDashboardResponse {
  payload: {
    data: {
      userDashboards: AvailableUserReport[]
    }
    error?: string
  }
}

export const useCreateDashboard = (
  onCreated: (dashboard?: AvailableUserReport) => void
) => {
  const { createDashboard } = useLookerData()

  const isLoading = useIsLoading('createDashboard')

  const submit = async ({ title, folderId }) => {
    if (!createDashboard) return

    const response = await createDashboard(title, folderId)

    const createDashboardResponse = response as CreateDashboardResponse

    if (!createDashboardResponse.payload.error) {
      const dashboard =
        createDashboardResponse.payload.data.userDashboards.find(
          d => d.label === title
        )
      onCreated(dashboard)
    }
  }

  return { submit, isLoading }
}
