import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { SubNavItemType } from '@chordco/component-library'

export type AnalyticsTab = {
  id: number
  slug: string
  title: string
  link: string
  subItems?: SubNavItemType[]
}

export const useAnalyticsTabsHelper = (tabs: AnalyticsTab[]) => {
  const history = useHistory()

  const [hasLoaded, setHasLoaded] = useState(false)
  const path = history.location.pathname

  const initialTab = tabs.find(
    t => t.link === path || t?.subItems?.some(si => si.link === path)
  )
  const [selectedTabId, setSelectedTabId] = useState<number>(
    initialTab?.id || tabs[0]?.id
  )

  useEffect(() => {
    const selectedTab = tabs.find(t => t.id === selectedTabId)
    if (!selectedTab) return

    if (!hasLoaded) {
      if (
        selectedTab.link !== path &&
        !selectedTab.subItems?.some(si => si.link === path)
      ) {
        history.replace(selectedTab.link)
      }

      setHasLoaded(true)
      return
    }

    if (selectedTab.subItems?.length) {
      if (!selectedTab.subItems.some(si => si.link === path)) {
        history.push(selectedTab.subItems?.[0]?.link)
      }
    } else {
      history.push(selectedTab.link)
    }
  }, [selectedTabId])

  useEffect(() => {
    const relevantTab = tabs.find(t => t.link === history.location.pathname)

    if (relevantTab && relevantTab.id !== selectedTabId) {
      setSelectedTabId(relevantTab.id)
    }
  }, [history.location])

  useEffect(() => {
    if (!tabs.length) return

    const path = history.location.pathname

    const currentTab = tabs.find(
      tab => tab.link === path || tab.subItems?.some(si => si.link === path)
    )

    if (currentTab) setSelectedTabId(currentTab.id)
  }, [tabs])

  return [selectedTabId, setSelectedTabId] as const
}
