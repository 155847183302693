import React from 'react'
import styled from 'styled-components'
import { Loading } from 'components/common'
import { DashboardEmbed } from './DashboardEmbed'
import useEmbedUrl from './useEmbedUrl'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { NavigationContext } from 'components/AuthenticatedRoutes'
import { useIsMobile } from 'hooks'
import { Button, H1, H2, standardIcons } from '@chordco/component-library'

interface RouteParams {
  slug: string
}

interface LocationState {
  title: string
}

export const Dashboard: React.FC = () => {
  const { slug } = useParams<RouteParams>()
  const location = useLocation<LocationState>()

  // If the title is not passed in the location state, use the slug as the title,
  // that's better than nothing...
  const { title } = location.state || { title: slug }

  const { embedUrls, isLoading } = useEmbedUrl(slug, 'dashboard')

  const isMobile = useIsMobile()
  const Heading = isMobile ? H2 : H1

  const history = useHistory()

  return (
    <NavigationContext.Consumer>
      {({ navOpen }) => (
        <Wrapper navOpen={navOpen}>
          <Header>
            <Heading>{title}</Heading>
            <ButtonsContainer>
              <Button
                purpose="secondary"
                icon={standardIcons.ArrowLeft}
                onClick={() => history.push('/analytics/reports')}
              >
                Back to Reports
              </Button>
            </ButtonsContainer>
          </Header>

          <Container>
            {isLoading ? <Loading /> : <DashboardEmbed url={embedUrls[slug]} />}
          </Container>
        </Wrapper>
      )}
    </NavigationContext.Consumer>
  )
}

const Wrapper = styled.div<{ navOpen: boolean }>`
  color: ${p => p.theme.ContentPrimary};
  height: 100vh;
  width: ${p => (p.navOpen ? 'calc(100vw - 280px)' : '100vw')};
  position: fixed;
  top: 0;
  right: 0;
`

const Header = styled.div`
  padding: 24px 32px 0px 64px;
  display: flex;
  justify-content: space-between;
`

const Container = styled.div`
  height: 100%;
  width: 100%;
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
`
