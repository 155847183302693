import React from 'react'
import styled from 'styled-components'
import { Button, Label, Modal } from '@chordco/component-library'
import { FormInput, FormTextArea } from 'components/common'
import { usePublishDashboard } from './usePublishDashboard'
import { useForm } from 'hooks'

interface PublishDashboardModalProps {
  dashboardName: string
  dashboardSlug: string
  onClose: () => void
  onPublish: () => void
}

export const PublishDashboardModal: React.FC<PublishDashboardModalProps> = ({
  dashboardName,
  dashboardSlug,
  onClose,
  onPublish,
}) => {
  const { submit, isLoading: isSubmitting } = usePublishDashboard(onPublish)

  const { fields, onSubmit } = useForm(
    {
      dashboardName: {
        label: 'Report Name',
        type: 'string',
        initialValue: dashboardName,
        required: true,
      },
      dashboardDescription: {
        label: 'Description',
        type: 'string',
        initialValue: '',
        required: false,
      },
      dashboardSlug: {
        label: 'Slug',
        type: 'string',
        initialValue: dashboardSlug,
        required: true,
      },
    },
    submit
  )

  return (
    <Modal
      onClose={onClose}
      title="Publish to Reports"
      width="480px"
      closeOnClickOutside={false}
    >
      <form onSubmit={onSubmit} noValidate>
        <Section>
          <StyledLabel>
            Publish your custom report to make it accessible to your entire
            team. Once published, the report will appear in the Analytics &gt;
            Reports section.
          </StyledLabel>
          <FormInput field={fields.dashboardName} customWidth="100%" required />
          <FormTextArea
            field={fields.dashboardDescription}
            customWidth="100%"
          />
        </Section>

        <BottomSection>
          <Button purpose="tertiary" onClick={onClose}>
            Cancel
          </Button>

          <Button type="submit" purpose="primary" isLoading={isSubmitting}>
            Publish
          </Button>
        </BottomSection>
      </form>
    </Modal>
  )
}

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledLabel = styled(Label)`
  white-space: pre-wrap;
`

const BottomSection = styled.div`
  width: 100%;
  padding: 16px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`
