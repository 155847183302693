import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useDebounce } from 'utils'
import { MenuItem, Select, TextField } from '@mui/material'
import {
  Button,
  capitalizeFirstLetter,
  H5,
  Modal,
  Spinner,
} from '@chordco/component-library'
import {
  CensusSync,
  CensusScheduleFrequency,
  CensusScheduleDay,
  CensusScheduleHours,
  CensusScheduleMinutes,
} from 'api/census/interfaces'
import { useCensus } from '../CensusContext'
import { useForm } from 'hooks'
import { formatCronExpression, formatLocalTime } from '../utils/time'

interface SyncEditScheduleProps {
  sync: CensusSync
  onScheduleEdited: (data: CensusSync) => void
  onScheduleError: (message: string) => void
  onClose: () => void
}

const SyncEditSchedule: React.FC<SyncEditScheduleProps> = ({
  sync,
  onScheduleEdited,
  onScheduleError,
  onClose,
}) => {
  const { censusClient } = useCensus()

  const [isUpdating, setIsUpdating] = useState(false)

  const [selectedFrequency, setSelectedFrequency] =
    useState<CensusScheduleFrequency>(sync.scheduleFrequency)

  const [cronDescription, setCronDescription] = useState('')

  const initialMount = useRef(true)

  const submit = async fields => {
    setIsUpdating(true)

    try {
      const schedule = {
        ...(fields.scheduleFrequency && {
          frequency: fields.scheduleFrequency,
        }),
        ...(fields.scheduleDay && { day: fields.scheduleDay }),
        ...(fields.scheduleHour && { hour: parseInt(fields.scheduleHour, 10) }),
        ...(fields.scheduleMinute && {
          minute: parseInt(fields.scheduleMinute, 10),
        }),
        ...(fields.scheduleExpression && {
          cron_expression: fields.scheduleExpression,
        }),
      }

      const response = await censusClient?.updateSyncSchedule(sync.id, schedule)

      const { status, data } = response ?? {}

      if (status === 'updated' && data) {
        // TODO: send slack event?
        // await sendSlackEvent('SYNC_UPDATED', {
        //   tenantName: currentTenant.name,
        //   destinationName: connector.label,
        //   hubUser: user,
        // })

        onScheduleEdited(data)
      } else {
        throw new Error(
          'An error occurred while editing the schedule. Please try again.'
        )
      }
    } catch (error: any) {
      onScheduleError(error.message)
    } finally {
      setIsUpdating(false)
    }
  }

  const { fields, onSubmit, resetField } = useForm(
    {
      scheduleFrequency: {
        type: 'string',
        initialValue: sync.scheduleFrequency,
        required: true,
      },
      scheduleDay: {
        type: 'string',
        initialValue: sync.scheduleDay,
      },
      scheduleHour: {
        type: 'string',
        initialValue: sync.scheduleHour ? sync.scheduleHour.toString() : '0',
      },
      scheduleMinute: {
        type: 'string',
        initialValue: sync.scheduleMinute
          ? sync.scheduleMinute.toString()
          : '0',
      },
      scheduleExpression: {
        type: 'string',
        initialValue: sync.cronExpression,
      },
    },
    submit
  )

  const frequencies: CensusScheduleFrequency[] = [
    'never',
    'weekly',
    'daily',
    'hourly',
    'quarter_hourly',
    'continuous',
    'expression',
  ]

  const frequencyLabels: Record<CensusScheduleFrequency, string> = {
    never: 'Manual',
    continuous: 'Continuous',
    quarter_hourly: 'Every 15 mn',
    hourly: 'Hourly',
    daily: 'Daily',
    weekly: 'Weekly',
    expression: 'Cron (UTC)',
  }

  const days: CensusScheduleDay[] = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]

  const hours: CensusScheduleHours[] = Array.from(
    { length: 24 },
    (_, index) => {
      const hour = index.toString() as CensusScheduleHours
      return hour
    }
  )

  const minutes: CensusScheduleMinutes[] = Array.from(
    { length: 12 },
    (_, index) => {
      const minute = (index * 5).toString() as CensusScheduleMinutes
      return minute
    }
  )

  useEffect(() => {
    setSelectedFrequency(
      fields.scheduleFrequency.value as CensusScheduleFrequency
    )
  }, [fields.scheduleFrequency.value])

  useEffect(() => {
    if (!initialMount.current) {
      resetField('scheduleDay', '')
      resetField('scheduleHour', '')
      resetField('scheduleMinute', '')
      resetField('scheduleExpression', '')
    } else {
      initialMount.current = false
    }
  }, [selectedFrequency])

  const debouncedCronExpression = useDebounce(
    fields.scheduleExpression.value,
    500
  )

  useEffect(() => {
    if (selectedFrequency === 'expression') {
      if (debouncedCronExpression === '') {
        setCronDescription('')
        return
      }

      try {
        setCronDescription(formatCronExpression(debouncedCronExpression))
      } catch (e) {
        setCronDescription('Invalid cron expression')
      }
    }
  }, [debouncedCronExpression, selectedFrequency])

  return (
    <Modal
      onClose={onClose}
      title="Edit Schedule"
      width="500px"
      padding="0"
      closeOnClickOutside={false}
    >
      <FormContainer onSubmit={onSubmit}>
        {isUpdating && (
          <SpinnerContainer>
            <Spinner scale={40} />
          </SpinnerContainer>
        )}

        {!isUpdating && (
          <>
            <Title>
              Schedule your sync to run automatically at a specific time.
            </Title>
            <Content>
              <Section>
                <Row>
                  <Label>Schedule</Label>
                  <CustomSelect
                    required
                    size="small"
                    value={fields.scheduleFrequency.value}
                    onChange={e =>
                      fields.scheduleFrequency.setValue(e.target.value)
                    }
                  >
                    {frequencies.map(frequency => (
                      <MenuItem value={frequency} key={frequency}>
                        {frequencyLabels[frequency as CensusScheduleFrequency]}
                      </MenuItem>
                    ))}
                  </CustomSelect>

                  {selectedFrequency === 'expression' && (
                    <Link
                      href="https://docs.getcensus.com/basics/triggering-syncs#cron-custom-schedules"
                      target="_blank"
                      pink
                    >
                      Learn
                      <br />
                      more
                    </Link>
                  )}
                </Row>
              </Section>

              {(selectedFrequency === 'weekly' ||
                selectedFrequency === 'daily') && (
                <>
                  {selectedFrequency === 'weekly' && (
                    <Section>
                      <Row>
                        <Label>Every</Label>
                        <CustomSelect
                          required
                          size="small"
                          value={fields.scheduleDay.value}
                          onChange={e =>
                            fields.scheduleDay.setValue(e.target.value)
                          }
                        >
                          {days.map(day => (
                            <MenuItem value={day} key={day}>
                              {capitalizeFirstLetter(day)}
                            </MenuItem>
                          ))}
                        </CustomSelect>
                      </Row>
                    </Section>
                  )}

                  <Section>
                    <Row>
                      <Label>At</Label>
                      <CustomSelect
                        required
                        width="80px"
                        size="small"
                        value={fields.scheduleHour.value}
                        onChange={e =>
                          fields.scheduleHour.setValue(e.target.value)
                        }
                      >
                        {hours.map(hour => (
                          <MenuItem value={hour} key={hour}>
                            {hour.padStart(2, '0')}
                          </MenuItem>
                        ))}
                      </CustomSelect>

                      <TimeSeparator>:</TimeSeparator>

                      <CustomSelect
                        required
                        width="80px"
                        size="small"
                        value={fields.scheduleMinute.value}
                        onChange={e =>
                          fields.scheduleMinute.setValue(e.target.value)
                        }
                      >
                        {minutes.map(minute => (
                          <MenuItem value={minute} key={minute}>
                            {minute.padStart(2, '0')}
                          </MenuItem>
                        ))}
                      </CustomSelect>

                      <TzLocalTime>
                        <TimeZone>UTC</TimeZone>

                        <LocalTime>
                          (
                          {formatLocalTime(
                            parseInt(fields.scheduleHour.value.toString()),
                            parseInt(fields.scheduleMinute.value.toString())
                          )}
                          )
                        </LocalTime>
                      </TzLocalTime>
                    </Row>
                  </Section>
                </>
              )}

              {selectedFrequency === 'hourly' && (
                <Section>
                  <Row>
                    <Label>At</Label>
                    <CustomSelect
                      required
                      width="80px"
                      size="small"
                      value={fields.scheduleMinute.value}
                      onChange={e =>
                        fields.scheduleMinute.setValue(e.target.value)
                      }
                    >
                      {minutes.map(minute => (
                        <MenuItem value={minute} key={minute}>
                          {minute.padStart(2, '0')}
                        </MenuItem>
                      ))}
                    </CustomSelect>

                    <TzLocalTime>
                      <LocalTime>minutes past the hour</LocalTime>
                    </TzLocalTime>
                  </Row>
                </Section>
              )}

              {selectedFrequency === 'expression' && (
                <Section>
                  <Row>
                    <Label>Expression</Label>
                    <Col>
                      <CustomTextField
                        value={fields.scheduleExpression.value}
                        onChange={e =>
                          fields.scheduleExpression.setValue(e.target.value)
                        }
                        variant="outlined"
                        size="small"
                        required
                      />

                      <Hint>{cronDescription}</Hint>
                    </Col>
                  </Row>
                </Section>
              )}
            </Content>
          </>
        )}

        <Hr />

        <BottomSection>
          <Button onClick={onClose} purpose="secondary">
            Cancel
          </Button>
          <Button
            isLoading={isUpdating}
            disabled={isUpdating}
            purpose="primary"
            type="submit"
          >
            Update
          </Button>
        </BottomSection>
      </FormContainer>
    </Modal>
  )
}

export const FormContainer = styled.form`
  padding: 0 24px;
  overflow: auto;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
`

const Section = styled.div`
  margin-bottom: 20px;
`

const Title = styled.div`
  margin-bottom: 40px;
`

const Label = styled(H5)`
  width: 90px;
  max-width: 90px;
  text-align: right;
  margin-top: 8px;
`

const TimeSeparator = styled(H5)`
  margin-top: 4px;
`

const BottomSection = styled.div`
  width: 100%;
  padding-bottom: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`

const Hr = styled.div`
  border-top: solid 1px ${p => p.theme.BorderHairline};
  margin: 16px -20px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const TimeZone = styled(H5)`
  margin-top: 4px;
`

const LocalTime = styled.div`
  font-size: 12px;
  margin-top: 4px;
`

const Link = styled.a<{ pink?: boolean }>`
  color: ${p => (p.pink ? p.theme.ContentAccentPrimary : 'inherit')};
  font-size: 12px;
  line-height: 16px;
`

const SpinnerContainer = styled.div`
  width: 100%;
  min-height: 130px;
`

const Hint = styled.div`
  bottom: -16px;
  left: 0;
  font-size: 12px;
  color: ${p => p.theme.ContentAccentPrimary};
  width: 300px;
`

const TzLocalTime = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin-top: 4px;
`

const CustomSelect = styled(Select)<{ width?: string }>`
  width: ${p => p.width || '300px'};
`

const CustomTextField = styled(TextField)`
  width: 300px;
`

export default SyncEditSchedule
