import React, { useState } from 'react'
import { Avatar, standardIcons, useTheme } from '@chordco/component-library'
import styled from 'styled-components'
import { UserMenu } from './UserMenu'
import { User } from 'types'
import { useTenantsData } from 'redux/state/tenants'

const { ChevronDown } = standardIcons

interface Props {
  user: User
}

export const Header: React.FC<Props> = ({ user }) => {
  const [showUserModal, setShowUserModal] = useState(false)
  const {
    state: { currentTenant },
  } = useTenantsData()

  const theme = useTheme()

  const name =
    ((user.firstName || user.lastName) &&
      `${user.firstName} ${user.lastName}`) ||
    'Mysterious User' // todo get this outta here

  return (
    <UserHeader>
      <Avatar
        logo
        src={user.company?.photo || '/images/ChordLogo.png'}
        size={40}
      />
      <UserAvatar>
        <Avatar userName={name} src={user.photo} size={16} />
      </UserAvatar>
      <Names>
        <CompanyName>{currentTenant?.name}</CompanyName>
        <UserName onClick={() => setShowUserModal(true)}>
          {name}
          <ChevronDown fill={theme.ContentSecondary} scale={16} />
        </UserName>
      </Names>

      {showUserModal && (
        <UserMenu
          name={name}
          email={user.email || ''}
          onClose={() => setShowUserModal(false)}
        />
      )}
    </UserHeader>
  )
}

export const UserHeader = styled.div`
  position: relative;
  display: flex;
  box-sizing: border-box;
  height: 72px;
  padding: 16px;
`

const UserAvatar = styled.div`
  position: absolute;
  top: 42px;
  left: 42px;
  height: 20px;
  width: 20px;
  box-sizing: border-box;
  border: solid 2px ${p => p.theme.ComponentNavBgMainNav};
  border-radius: 50%;

  span {
    position: absolute;
  }
`

const Names = styled.div`
  margin-left: 16px;
`

const CompanyName = styled.div`
  position: relative;
  width: fit-content;
  font-weight: 600;
  margin-bottom: 0;
  color: ${p => p.theme.ContentPrimary};
  text-transform: capitalize;
`

const UserName = styled.div`
  font-size: 12px;
  color: ${p => p.theme.ContentSecondary};
  line-height: 16px;
  cursor: pointer;

  svg {
    transform: translateY(4px);
  }
`
