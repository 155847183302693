import React, { useState } from 'react'
import { standardIcons } from '../icons'
import styled from 'styled-components'
import { useClickOutsideWithRef } from '../../hooks'
import { Button, Icon } from '../controls'
import { H3 } from '../type'
import { SaveChangesModal } from './SaveChangesModal'

interface Props {
  children: React.ReactNode
  sheetRef: any
  title: string
  closeSheet: any
  isDirty?: boolean
  onSubmit?: any
}

export interface SheetFormProps {
  closeSheet: any
  sheetRef: any
}

export const SheetActions: React.FC<Props> = ({
  title,
  sheetRef,
  closeSheet,
  isDirty,
  onSubmit,
  children,
}) => {
  const [showSaveChangesModal, setShowSaveChangesModal] = useState(false)

  const onExit = () => (isDirty ? setShowSaveChangesModal(true) : closeSheet())

  useClickOutsideWithRef(sheetRef, onExit, [isDirty])

  return (
    <Container>
      <Title>{title}</Title>
      <Close>
        <Icon
          icon={standardIcons.Exit}
          size="small"
          purpose="ghost"
          onClick={() => onExit()}
        />
      </Close>
      <div>{children}</div>
      {isDirty && (
        <SaveChanges>
          <Button
            size="medium"
            purpose="primary"
            onClick={() => {
              const success = onSubmit()
              success && closeSheet()
            }}
          >
            Save changes
          </Button>
          <Button size="medium" purpose="secondary" onClick={closeSheet}>
            Cancel
          </Button>
        </SaveChanges>
      )}

      {showSaveChangesModal && (
        <SaveChangesModal
          closeModal={() => setShowSaveChangesModal(false)}
          onSubmit={onSubmit}
          discardAndClose={closeSheet}
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  width: 680px;
`

const Title = styled(H3)`
  position: absolute;
  top: 24px;
  left: 24px;
  margin-bottom: 24px;
`

const Close = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
`

const SaveChanges = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 16px 24px;
  border-top: solid 1px ${p => p.theme.BorderHairline};
  background-color: ${p => p.theme.ComponentWorkspaceBgSheet};

  button {
    float: right;
    margin-left: 8px;
  }
`
