import { MRT_TableOptions, MRT_RowData } from 'material-react-table'

export function getCommonProps<TData extends MRT_RowData>(
  enableEditing: boolean
): Partial<MRT_TableOptions<TData>> {
  return {
    paginationDisplayMode: 'pages',
    createDisplayMode: 'modal',
    editDisplayMode: 'modal',
    positionActionsColumn: 'last',
    enableEditing: enableEditing,
    enableRowActions: enableEditing,
    enableRowNumbers: true,
    enableColumnFilters: false,
    getRowId: row => row.key,
    muiTableBodyRowProps: ({ row, table }) => ({
      onClick: () => {
        table.setEditingRow(row)
      },
      sx: {
        cursor: 'pointer',
      },
    }),
    muiTableBodyProps: {
      sx: {
        // stripe the rows, make odd rows a darker color
        '& tr:nth-of-type(odd) > td': {
          backgroundColor: '#f5f5f5',
        },
      },
    },
    displayColumnDefOptions: {
      'mrt-row-numbers': {
        muiTableBodyCellProps: {
          sx: {
            color: '#B0B0B0',
          },
        },
      },
    },
  }
}

export function getMuiProps<TData extends MRT_RowData>(): Partial<
  MRT_TableOptions<TData>
> {
  return {
    muiPaginationProps: {
      color: 'primary',
      shape: 'rounded',
      showRowsPerPage: true,
      variant: 'outlined',
    },
    muiTableContainerProps: {
      sx: {
        minHeight: '500px',
      },
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: '0',
        border: 'none',
      },
    },
    muiBottomToolbarProps: {
      sx: {
        boxShadow: 'none',
      },
    },
    muiTableHeadCellProps: {
      sx: {
        textTransform: 'capitalize',
      },
    },
    muiCreateRowModalProps: {
      open: true,
      sx: {
        textTransform: 'capitalize',
      },
    },
    muiEditRowDialogProps: {
      open: true,
      sx: {
        textTransform: 'capitalize',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        borderRight: '1px solid #E0E0E0', // add a border between columns
      },
    },
    muiEditTextFieldProps: {
      sx: {
        '& input': {
          textTransform: 'none',
        },
        '& select': {
          textTransform: 'none',
        },
        marginTop: '16px',
        marginBottom: '16px',
      },
    },
  }
}
