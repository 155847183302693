import { createSlice } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import { useStateSliceAndDispatch } from '../utils'
import { LookerReportType, LookerState, MetricsLookupIds } from 'types'
import { useTenantsData } from 'redux/state/tenants'
import { reduxApiClient } from 'redux/api'
import { DateDropdownPeriod } from '@chordco/component-library'

const SLICE_NAME = 'looker'

const initialState: LookerState = {
  looks: {},
  availableReports: {
    dashboards: [],
    explores: [],
  },
  availableUserReports: {
    userDashboards: [],
  },
  availableAudiences: {
    audiences: [],
  },
  availableUserAudiences: {
    userAudiences: [],
  },
  tiles: [],
  metrics: {},
  embedUrls: {},
  embedUserUrls: {},
  embedAudienceUrls: {},
  embedUserAudienceUrls: {},
}

const {
  getAvailableReports,
  getAvailableUserReports,
  getAvailableAudiences,
  getAvailableUserAudiences,
  getEmbedUrl,
  getEmbedUserUrl,
  getEmbedAudienceUrl,
  getEmbedUserAudienceUrl,
  runLook,
  getTiles,
  getMetrics,
  createDashboard,
  getEmbedUser,
  createEmbedUser,
  copyDashboard,
  replaceDashboard,
  publishDashboard,
  copyAudience,
  activateAudience,
} = reduxApiClient

// Reusable function for handling Embed URL API fulfilled cases
const handleEmbedUrlFulfilledCase = (
  state: LookerState,
  action: any,
  stateKey: 'embedUrls' | 'embedUserUrls' | 'embedAudienceUrls',
  sentryMessage: string
) => {
  const { slug } = action.meta.arg;
  const data = action.payload?.data;

  if (!data || !data.url) {
    Sentry.withScope(scope => {
      scope.setTag('embed_url_api_error', true);
      scope.setLevel(Sentry.Severity.Error);
      scope.setExtra('actionPayload', action.payload);
      scope.setExtra('metaArg', action.meta.arg);
      scope.setExtra('slug', slug);
      scope.setExtra('response', action.payload);
      Sentry.captureMessage(sentryMessage);
    });

    // fallback to empty string
    (state[stateKey] as Record<string, string>)[slug] = '';
  } else {
    const { url } = data;
    (state[stateKey] as Record<string, string>)[slug] = url;
  }
}

export const lookerSlice = createSlice({
  name: 'looker',
  initialState,
  reducers: { resetLooker: () => initialState },
  extraReducers: builder => {
    builder.addCase(getAvailableReports.fulfilled, (state, action) => {
      state.availableReports = action.payload.data
    })

    builder.addCase(getAvailableUserReports.fulfilled, (state, action) => {
      state.availableUserReports = action.payload.data
    })

    builder.addCase(getAvailableAudiences.fulfilled, (state, action) => {
      state.availableAudiences = action.payload.data
    })

    builder.addCase(getAvailableUserAudiences.fulfilled, (state, action) => {
      state.availableUserAudiences = action.payload.data
    })

    builder.addCase(getEmbedUrl.fulfilled, (state, action) => {
      handleEmbedUrlFulfilledCase(
        state,
        action,
        'embedUrls',
        'getEmbedUrl has returned an unexpected API response structure: Missing URL'
      )
    })

    builder.addCase(getEmbedUserUrl.fulfilled, (state, action) => {
      handleEmbedUrlFulfilledCase(
        state,
        action,
        'embedUserUrls',
        'getEmbedUserUrl has returned an unexpected API response structure: Missing URL'
      )
    })

    builder.addCase(getEmbedAudienceUrl.fulfilled, (state, action) => {
      handleEmbedUrlFulfilledCase(
        state,
        action,
        'embedAudienceUrls',
        'getEmbedAudienceUrl has returned an unexpected API response structure: Missing URL'
      )
    })

    builder.addCase(getEmbedUserAudienceUrl.fulfilled, (state, action) => {
      const { slug } = action.meta.arg
      const { url } = action.payload.data

      state.embedUserAudienceUrls = {
        ...state.embedUserAudienceUrls,
        [slug]: url,
      }
    })

    builder.addCase(getTiles.fulfilled, (state, action) => {
      state.tiles = [...action.payload.data].sort((a, b) => a.id - b.id)
    })

    builder.addCase(runLook.fulfilled, (state, action) => {
      const lookId = action.meta.arg.lookId
      const lookData = action.payload.data

      // todo prevents setting state.looks to have an error and blow up tiles.
      // todo the error can be an object with a 'message' field
      if (
        Array.isArray(lookData)
          ? lookData.some(d => !!d?.lookerError)
          : lookData?.message
      ) {
        return
      }

      state.looks = { ...state.looks, [lookId]: lookData }
    })

    builder.addCase(getMetrics.fulfilled, (state, action) => {
      action.payload.data.forEach(metric => {
        state.metrics[metric.id] = metric
      })
    })

    builder.addCase(createDashboard.fulfilled, (state, action) => {
      state.availableUserReports.userDashboards.push(action.payload.data)
    })

    builder.addCase(getEmbedUser.fulfilled, (state, action) => {
      state.embedUser = action.payload.data
    })

    builder.addCase(createEmbedUser.fulfilled, (state, action) => {
      state.embedUser = action.payload.data
    })

    builder.addCase(copyDashboard.fulfilled, (state, action) => {
      state.availableUserReports.userDashboards.push(action.payload.data)
    })

    builder.addCase(publishDashboard.fulfilled, (state, action) => {
      state.availableReports.dashboards.push(action.payload.data)
    })

    builder.addCase(copyAudience.fulfilled, (state, action) => {
      state.availableUserAudiences.userAudiences.push(action.payload.data)
    })
  },
})

export default lookerSlice.reducer

export const useLookerData = () => {
  const { dispatch, state } = useStateSliceAndDispatch(SLICE_NAME)

  const {
    state: { currentTenant, currentStore },
  } = useTenantsData()

  const tenantId = currentTenant?.id
  const storeId = currentStore?.id

  if (!tenantId || !storeId) {
    return { state }
  }

  return {
    state,
    getAvailableReports: () =>
      dispatch(getAvailableReports({ tenantId, storeId })),
    getAvailableUserReports: () =>
      dispatch(getAvailableUserReports({ tenantId, storeId })),
    getAvailableUserAudiences: () =>
      dispatch(getAvailableUserAudiences({ tenantId })),
    getEmbedUrl: (slug: string, type: LookerReportType, theme?: string) =>
      dispatch(getEmbedUrl({ tenantId, storeId, slug, type, theme })),
    getEmbedUserUrl: (slug: string, type: LookerReportType, theme?: string) =>
      dispatch(getEmbedUserUrl({ tenantId, storeId, slug, type, theme })),
    runLook: (lookId: number) =>
      dispatch(runLook({ tenantId, storeId, lookId })),
    getTiles: (timePeriod: DateDropdownPeriod) =>
      dispatch(getTiles({ tenantId, storeId, timePeriod })),
    getMetrics: (metricIds: (MetricsLookupIds | number)[]) =>
      dispatch(getMetrics({ tenantId, storeId, metricIds })),
    createDashboard: (title: string, folderId: string) =>
      dispatch(createDashboard({ tenantId, storeId, title, folderId })).then(
        () => dispatch(getAvailableUserReports({ tenantId, storeId }))
      ),
    getEmbedUser: () => dispatch(getEmbedUser()),
    createEmbedUser: () => dispatch(createEmbedUser()),
    copyDashboard: (dashboardSlug: string, folderId: string) =>
      dispatch(
        copyDashboard({ tenantId, storeId, dashboardSlug, folderId })
      ).then(() => dispatch(getAvailableUserReports({ tenantId, storeId }))),
    replaceDashboard: (oldDashboardSlug: string, newDashboardSlug: string) =>
      dispatch(
        replaceDashboard({
          tenantId,
          storeId,
          oldDashboardSlug,
          newDashboardSlug,
        })
      ).then(() => dispatch(getAvailableReports({ tenantId, storeId }))),
    publishDashboard: (
      dashboardName: string,
      dashboardDescription: string,
      dashboardSlug: string
    ) =>
      dispatch(
        publishDashboard({
          tenantId,
          storeId,
          dashboardName,
          dashboardDescription,
          dashboardSlug,
        })
      ).then(() => dispatch(getAvailableReports({ tenantId, storeId }))),
    getAvailableAudiences: () => dispatch(getAvailableAudiences({ tenantId })),
    getEmbedAudienceUrl: (
      slug: string,
      type: LookerReportType,
      theme?: string
    ) => dispatch(getEmbedAudienceUrl({ tenantId, slug, type, theme })),
    getEmbedUserAudienceUrl: (slug: string, theme?: string) =>
      dispatch(getEmbedUserAudienceUrl({ tenantId, slug, theme })),
    copyAudience: (
      audienceId: string,
      audienceTitle: string,
      audienceDescription: string,
      folderId: string
    ) =>
      dispatch(
        copyAudience({
          tenantId,
          audienceId,
          audienceTitle,
          audienceDescription,
          folderId,
        })
      ).then(() => dispatch(getAvailableUserAudiences({ tenantId }))),
    activateAudience: (
      audienceName: string,
      audienceDescription: string,
      audienceId: string
    ) =>
      dispatch(
        activateAudience({
          tenantId,
          audienceName,
          audienceDescription,
          audienceId,
        })
      ),
  }
}
