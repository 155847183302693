import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useLookerData } from 'redux/state/looker'
import {
  Button,
  DateDropdown,
  DateDropdownPeriod,
  H4,
  H3,
  Spinner,
  standardIcons,
} from '@chordco/component-library'
import { Metrics } from './Metrics'
import { SubSection } from './Home'
import { useIsMobile } from 'hooks'
import { AnalyticsBlurbBanner } from './AnalyticsBlurbBanner'
import { useExperiments } from 'hooks/useExperiments'
import { useHistory } from 'react-router'
import { useIsLoading } from 'redux/utils'
import { EmptyMetrics } from './EmptyMetrics'
import { useTenantsData } from 'redux/state/tenants'

const { ArrowRight } = standardIcons

export const MetricsTiles = () => {
  const [period, setPeriod] = useState<DateDropdownPeriod>('week')

  const {
    state: { tiles },
    getTiles,
  } = useLookerData()

  const {
    state: { currentTenant },
  } = useTenantsData()

  // Retrieve the look IDs from the tenant metadata
  const looks = currentTenant?.metadata?.looks || {}

  // Filter out any looks that are not in the tenant metadata.
  // This is to prevent the "time selection" dropdown from showing
  // looks that are not available for the current tenant.
  const filteredOptions = Object.keys(looks).filter(key => key in looks)

  const tilesTreatment = useExperiments('hub_tiles_status')

  const tilesEnabled = tilesTreatment?.variation === 'on'

  useEffect(() => {
    if (tilesEnabled) getTiles && getTiles(period)
  }, [period, tilesEnabled])

  const isMobile = useIsMobile()

  const history = useHistory()
  const goToAnalytics = () => history.push('/analytics')

  const loading = useIsLoading('getTiles')

  return (
    <>
      <H3>Store Overview</H3>

      {tilesEnabled && (
        <DropdownContainer>
          <DateDropdown
            selected={period}
            onSelect={setPeriod}
            allowedOptions={filteredOptions}
          />
        </DropdownContainer>
      )}

      <SubSection isMobile={isMobile}>
        {!tilesEnabled && (
          <AnalyticsBlurbBanner
            message="Your data will show up once Looker has been configured."
            status="off"
          />
        )}
        <MetricsTitle>Performance</MetricsTitle>
        {tilesEnabled ? <Metrics metrics={tiles} /> : <EmptyMetrics />}

        {loading && <Spinner scale={30} />}
      </SubSection>
      <ButtonContainer>
        <Button
          purpose="tertiary"
          trailingIcon={ArrowRight}
          onClick={goToAnalytics}
        >
          View All Analytics
        </Button>
      </ButtonContainer>
    </>
  )
}

const MetricsTitle = styled(H4)`
  margin: 16px 0;
`

const DropdownContainer = styled.div`
  margin-top: 16px;
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 4px;
  margin-bottom: 64px;
`
