import React, { ReactNode, useState } from 'react'
import styled from 'styled-components'
import { useTheme, Palette } from '@chordco/component-library'
import { standardIcons } from '../icons'
import { Avatar } from '../information'

export type ChipColor =
  | Palette
  | (
      | 'neutral'
      | 'pink'
      | 'limeGreen'
      | 'brightBlue'
      | 'blue'
      | 'green'
      | 'red'
      | 'amber'
      | 'lightpink'
      | 'orange'
      | 'lightGreen'
    )

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text: ReactNode
  color?: ChipColor
  variant?: 'outlined' | 'solid' | 'solid-secondary'
  leftIcon?: any
  rightIcon?: any
  leftIconSize?: number
  rightIconSize?: number
  leftIconFill?: string
  rightIconFill?: string
  avatarSrc?: any
  onClose?: Function
}

export const Chip: React.FC<Props> = ({
  text,
  color = 'neutral',
  variant = 'outlined',
  leftIcon: LeftIcon,
  rightIcon: RightIcon,
  leftIconSize = 16,
  rightIconSize = 16,
  leftIconFill,
  rightIconFill,
  avatarSrc,
  onClose,
  ...props
}) => {
  const theme = useTheme()

  const colors: Record<
    'outlined' | 'solid' | 'solid-secondary',
    Record<ChipColor, string>
  > = {
    outlined: {
      neutral: theme.BorderAccentNeutral,
      primary: theme.BorderAccentPrimaryLight,
      secondary: theme.BorderAccentSecondary,
      tertiary: theme.BorderAccentTertiary,
      info: theme.BorderAccentInfo,
      success: theme.BorderAccentSuccess,
      error: theme.BorderAccentError,
      warning: theme.BorderAccentWarning,
      // v1 colors
      pink: theme.PRIMARY_50,
      limeGreen: theme.LIME_GREEN_50_BASE,
      brightBlue: theme.BRIGHT_BLUE_50_BASE,
      blue: theme.BLUE_40,
      green: theme.GREEN_70_BASE,
      red: theme.RED_50,
      amber: theme.AMBER_50_BASE,
      lightpink: theme.PRIMARY_20,
      lightGreen: theme.GREEN_20,
      orange: theme.ORANGE_50,
    },
    solid: {
      neutral: theme.BgAccentBrightAADarkNeutral,
      primary: theme.BgAccentBrightAADarkPrimary,
      secondary: theme.BgAccentBrightAADarkSecondary,
      tertiary: theme.BgAccentBrightAADarkTertiary,
      info: theme.BgAccentBrightAADarkInfo,
      success: theme.BgAccentBrightAADarkSuccess,
      error: theme.BgAccentBrightAADarkError,
      warning: theme.BgAccentBrightAADarkWarning,
      // v1 colors
      pink: theme.PRIMARY_50,
      limeGreen: theme.LIME_GREEN_50_BASE,
      brightBlue: theme.BRIGHT_BLUE_50_BASE,
      blue: theme.BLUE_40,
      green: theme.GREEN_70_BASE,
      red: theme.RED_50,
      amber: theme.AMBER_50_BASE,
      lightGreen: theme.GREEN_20,
      lightpink: theme.PRIMARY_20,
      orange: theme.ORANGE_50,
    },
    'solid-secondary': {
      neutral: theme.BgAccentAdaptiveAAPrimaryContentNeutral,
      primary: theme.BgAccentAdaptiveAAPrimaryContentPrimary,
      secondary: theme.BgAccentAdaptiveAAPrimaryContentSecondary,
      tertiary: theme.BgAccentAdaptiveAAPrimaryContentTertiary,
      info: theme.BgAccentAdaptiveAAPrimaryContentInfo,
      success: theme.BgAccentAdaptiveAAPrimaryContentSuccess,
      error: theme.BgAccentAdaptiveAAPrimaryContentError,
      warning: theme.BgAccentAdaptiveAAPrimaryContentWarning,
      // v1 colors
      pink: theme.PRIMARY_50,
      limeGreen: theme.LIME_GREEN_50_BASE,
      brightBlue: theme.BRIGHT_BLUE_50_BASE,
      blue: theme.BLUE_40,
      green: theme.GREEN_70_BASE,
      red: theme.RED_50,
      amber: theme.AMBER_50_BASE,
      lightpink: theme.PRIMARY_20,
      lightGreen: theme.GREEN_20,
      orange: theme.ORANGE_20,
    },
  }

  const [tabbed, setTabbed] = useState(false)

  const onClick = (e: any) => {
    setTabbed(false)
    props.onClick && props.onClick(e)
  }

  const onKeyDown = () => {
    setTabbed(true)
  }

  const { Exit } = standardIcons

  const chipColor = colors[variant]?.[color] ?? colors.solid[color]

  return (
    // @ts-ignore
    <Button
      {...props}
      onClick={onClick}
      onKeyDown={onKeyDown}
      tabbed={tabbed}
      color={chipColor}
      variant={variant}
      as={props.onClick ? 'button' : 'div'}
    >
      {LeftIcon && (
        <LeftContainer fill={leftIconFill ?? theme.ContentPrimary}>
          <LeftIcon scale={leftIconSize} />
        </LeftContainer>
      )}
      {avatarSrc && !LeftIcon && (
        <LeftContainer fill={leftIconFill ?? theme.ContentPrimary}>
          <Avatar size={16} src={avatarSrc} />
        </LeftContainer>
      )}
      <Text>{text}</Text>
      {!onClose && RightIcon && (
        <RightContainer fill={rightIconFill ?? theme.ContentPrimary}>
          <RightIcon scale={rightIconSize} />
        </RightContainer>
      )}
      {onClose && (
        <ExitContainer
          onClick={(e: any) => {
            e.stopPropagation()
            onClose()
          }}
        >
          <Exit scale={16} fill={theme.ContentPrimary} />
        </ExitContainer>
      )}
    </Button>
  )
}

const Button = styled.button<{
  color: string
  variant?: Props['variant']
  tabbed?: boolean
}>`
  box-sizing: border-box;
  display: inline-flex;
  height: 24px;
  border-radius: ${p => p.theme.TagsAndChipsRadius};
  padding: 4px 8px;
  color: ${p =>
    p.variant === 'solid' ? p.theme.BLACK : p.theme.ContentPrimary};
  background-color: ${p =>
    p.variant === 'outlined' ? 'transparent' : p.color};
  border: 1px solid ${p => p.color};
  outline: none;
  transition: 100ms;
  white-space: nowrap;

  path {
    fill: ${p => p.color};
  }

  button& {
    cursor: pointer;
  }

  button&:hover {
    background-color: ${p => p.theme.ComponentButtonTertiaryBgHover};
  }

  button&:focus {
    outline-color: ${p => p.theme.ComponentButtonBorderFocused};
  }
`

const Text = styled.span`
  font-size: 12px;
  text-transform: capitalize;
  display: inline-flex;
  align-items: center;
`

const LeftContainer = styled.div<{ fill: string }>`
  margin: 0 4px 0 0;
  display: inline-flex;
  align-items: center;

  svg path {
    fill: ${p => p.fill};
  }
`

const RightContainer = styled.div<{ fill: string }>`
  margin: 0 0 0 4px;
  display: inline-flex;
  align-items: center;

  svg path {
    fill: ${p => p.fill};
  }
`

const ExitContainer = styled.div`
  height: 16px;
  width: 16px;
  box-sizing: border-box;
  margin: -1px -2px 0 3px;
  border-radius: 4px;
  cursor: pointer;

  :hover {
    background-color: ${p => p.theme.GREY_80}14;
  }

  :active {
    background-color: ${p => p.theme.GREY_80}28;
  }
`
