import React, { FormEventHandler, useEffect } from 'react'
import styled from 'styled-components'
import SimpleBar from 'simplebar-react'
import { BusinessErrorEvent } from 'types/businessError'
import { Timeline, Loading } from 'components/common'
import { Sheet } from '@chordco/component-library'
import { useIsLoading } from 'redux/utils'

interface Props {
  onClose: Function
  getBusinessErrorEvents: Function
  title: string
  tenantId?: number
  issueId?: number
  events: BusinessErrorEvent[]
}

const Container: React.FC<{
  children?: React.ReactNode
  useScrollBar: boolean
}> = ({ children }) => (
  <SimpleBar
    style={{
      height: 'calc(100vh - 149px)',
      transition: '300ms',
      overflowX: 'hidden',
    }}
  >
    <Content>{children}</Content>
  </SimpleBar>
)

export const BusinessErrorsSheet: React.FC<Props> = ({
  onClose,
  title,
  tenantId,
  issueId,
  getBusinessErrorEvents,
  events,
}) => {
  useEffect(() => {
    if (tenantId && issueId) getBusinessErrorEvents({ tenantId, issueId })
  }, [tenantId, issueId])

  const timeline = events.map(event => ({
    id: event.id,
    label: event.title,
    dateIso: event.created,
  }))

  const isLoading = useIsLoading('getBusinessErrorEvents')

  return (
    <>
      <Sheet title={title} onClose={onClose} isLarge closeOnClickOutside>
        {isLoading && <Loading />}

        <Wrapper isLarge>
          <Container useScrollBar>
            <Timeline timeline={timeline} isSmall showTime />
          </Container>
        </Wrapper>
      </Sheet>
    </>
  )
}

export const Wrapper = styled.div<{
  isLarge?: boolean
  onSubmit?: FormEventHandler<HTMLFormElement>
}>`
  box-sizing: border-box;
  position: relative;
  min-height: calc(100vh - 76px);
  padding: ${p => `0px ${p.isLarge ? 24 : 16}px 72px ${p.isLarge ? 24 : 16}px`};
  overflow: auto;

  & > div:not(:first-child) {
    margin-top: 20px;
  }
`

const Content = styled.div`
  margin: 8px 0;
`
