import mixpanel, {
  Config,
  type Mixpanel as LoadedMixpanel,
  type Persistence,
} from 'mixpanel-browser'

import { User } from 'types'

export default class Mixpanel {
  private loadedMixpanel: LoadedMixpanel | null = null

  public async initialize(
    token: string,
    options?: {
      debug?: boolean
      track_pageview?: Config['track_pageview']
      persistence?: Persistence
      verbose?: boolean
    }
  ): Promise<void> {
    return new Promise(resolve => {
      mixpanel.init(token, {
        ...options,
        loaded: loadedMixpanel => {
          this.loadedMixpanel = loadedMixpanel
          resolve()
        },
      })
    })
  }

  /**
   * @see https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#sending-events
   */
  public trackEvent(
    eventName: string,
    eventData?: Record<string, string>
  ): void {
    this.loadedMixpanel?.track(eventName, eventData ?? {})
  }

  /**
   * @see https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#tracking-page-views
   */
  public trackPageView(): void {
    this.loadedMixpanel?.track_pageview()
  }

  /**
   * @see https://docs.mixpanel.com/docs/tracking/how-tos/identifying-users#what-is-distinct-id
   * @see https://docs.mixpanel.com/docs/tracking/how-tos/user-profiles
   * @see https://docs.mixpanel.com/docs/data-structure/user-profiles#reserved-user-properties
   */
  public identify(user: User): void {
    this.loadedMixpanel?.identify(user.id.toString())

    this.loadedMixpanel?.people.set({
      $name: `${user.firstName} ${user.lastName}`,
      $first_name: user.firstName,
      $last_name: user.lastName,
      $email: user.email,
      Role: user.role,
      Tenant: user.company?.name,
    })
  }

  /**
   * @see https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#call-reset-at-logout
   */
  public resetUser(): void {
    this.loadedMixpanel?.reset()
  }
}
