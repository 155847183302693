import React from 'react'
import { Button, Label, Modal } from '@chordco/component-library'
import { FormInput } from 'components/common'
import { useForm } from 'hooks'
import styled from 'styled-components'
import { useCopyDashboard } from './useCopyDashboard'
import { AvailableUserReport } from 'types'

interface CopyDashboardModalProps {
  folderId: string
  onClose: () => void
  onCopied: (dashboard?: AvailableUserReport) => void
}

export const CopyDashboardModal: React.FC<CopyDashboardModalProps> = ({
  folderId,
  onClose,
  onCopied,
}) => {
  const { submit, isLoading } = useCopyDashboard(onCopied)

  const { fields, onSubmit } = useForm(
    {
      dashboardUrl: {
        label: 'Dashboard URL',
        type: 'string',
        required: true,
      },
      folderId: {
        label: 'Folder ID',
        type: 'string',
        required: true,
        initialValue: folderId,
      },
    },
    submit
  )

  return (
    <Modal
      onClose={onClose}
      title="Import Dashboard"
      width="480px"
      closeOnClickOutside={false}
    >
      <FormContainer onSubmit={onSubmit} noValidate>
        <Section>
          <StyledLabel>
            Paste the URL of the dashboard you want to import, then click{' '}
            <strong>Import Dashboard</strong>. A copy of the dashboard will be
            created in your personal folder.
          </StyledLabel>
          <FormInput field={fields.dashboardUrl} customWidth="100%" required />
        </Section>

        <BottomSection>
          <Button purpose="tertiary" onClick={onClose}>
            Cancel
          </Button>

          <Button
            type="submit"
            purpose="primary"
            isLoading={isLoading}
            disabled={isLoading}
          >
            Import Dashboard
          </Button>
        </BottomSection>
      </FormContainer>
    </Modal>
  )
}

export const FormContainer = styled.form`
  overflow: auto;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledLabel = styled(Label)`
  white-space: pre-wrap;
`

const BottomSection = styled.div`
  width: 100%;
  padding: 16px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`
